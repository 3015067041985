import { SuggestionOption, SuggestionOptionConfig, SuggestionType } from 'src/models/ChatMessage';

/**
 * Configuration to hold suggestion option data for each suggestion option type to allow correct suggestion with action to be available on UI
 */
export const SUGGESTION_OPTION_MAPPING: Record<SuggestionOption, SuggestionOptionConfig> = {
  [SuggestionOption.CHECK_UPT_BALANCE_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I want to check my UPT balance',
  },
  [SuggestionOption.CHANGE_PUNCH_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I want to view my punches',
  },
  [SuggestionOption.LATE_FOR_SHIFT_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I will be late for my shift',
  },
  [SuggestionOption.PLANNING_TIME_OFF_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I want to take time off',
  },
  [SuggestionOption.CHECK_TIME_OFF_BALANCE_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: "What's my time off balance?",
  },
  [SuggestionOption.CHECK_UPT_HISTORY_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I want to review my UPT history',
  },
  [SuggestionOption.APPLY_TIMEOFF]: {
    optionType: SuggestionType.ATOZ_WORKFLOW_LINK,
    optionText: 'Apply Timeoff to refund UPT',
    url: 'https://essportal.integ.amazon.com/time',
  },
  [SuggestionOption.REQUEST_ABSENCE]: {
    optionType: SuggestionType.ATOZ_WORKFLOW_LINK,
    optionText: 'Request for Absence',
    url: 'https://essportal.integ.amazon.com/time',
  },
  [SuggestionOption.CORRECT_PUNCHES]: {
    optionType: SuggestionType.ATOZ_WORKFLOW_LINK,
    optionText: 'Correct Punch to refund UPT',
    optionInfo:
      'You have been recorded with multiple missed punches contributing to your UPT deductions. ' +
      'You need to correct your punches to keep your UPT balance in positive',
    url: 'https://essportal.integ.amazon.com/timecard/correct-punches',
  },
  [SuggestionOption.CORRECT_SCHEDULE]: {
    optionType: SuggestionType.EXTERNAL_LINK,
    optionText: 'My schedule is incorrect',
    optionInfo:
      'You have been recorded with multiple missed punches contributing to your UPT deductions. ' +
      'You need to apply for time off to keep your UPT balance in positive',
    url: '/timecard/correct-schedule',
  },
  [SuggestionOption.SURVEY_LINK]: {
    optionType: SuggestionType.SURVEY_MODAL,
    optionText: 'Survey link',
  },
};


export const HRSC_SUGGESTION_OPTION_MAPPING: Record<SuggestionOption, SuggestionOptionConfig> = {
  [SuggestionOption.CHECK_UPT_BALANCE_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I want to check my UPT balance',
  },
  [SuggestionOption.CHANGE_PUNCH_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I want to view my punches',
  },
  [SuggestionOption.LATE_FOR_SHIFT_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I will be late for my shift',
  },
  [SuggestionOption.PLANNING_TIME_OFF_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I want to take time off',
  },
  [SuggestionOption.CHECK_TIME_OFF_BALANCE_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: "What's my time off balance?",
  },
  [SuggestionOption.CHECK_UPT_HISTORY_MESSAGE]: {
    optionType: SuggestionType.MESSAGE_TEXT,
    optionText: 'I want to review my UPT history',
  },
  [SuggestionOption.APPLY_TIMEOFF]: {
    optionType: SuggestionType.ATOZ_WORKFLOW_LINK,
    optionText: 'Assist AA to resolve UPT by applying time off',
    url: 'https://essportal.integ.amazon.com/time?testas=111563051',
  },
  [SuggestionOption.REQUEST_ABSENCE]: {
    optionType: SuggestionType.ATOZ_WORKFLOW_LINK,
    optionText: 'Assist AA to request for Absence',
    url: 'https://essportal.integ.amazon.com/time?testas=111563051',
  },
  [SuggestionOption.CORRECT_PUNCHES]: {
    optionType: SuggestionType.ATOZ_WORKFLOW_LINK,
    optionText: 'Assist AA to resolve UPT by correcting missed punches',
    optionInfo:
      'You have been recorded with multiple missed punches contributing to your UPT deductions. ' +
      'You need to correct your punches to keep your UPT balance in positive',
    url: 'https://essportal.integ.amazon.com/timecard/correct-punches?testas=111563051',
  },
  [SuggestionOption.CORRECT_SCHEDULE]: {
    optionType: SuggestionType.EXTERNAL_LINK,
    optionText: 'Assist AA to correct Schedule',
    optionInfo:
      'You have been recorded with multiple missed punches contributing to your UPT deductions. ' +
      'You need to apply for time off to keep your UPT balance in positive',
    url: '/timecard/correct-schedule?testas=111563051',
  },
  [SuggestionOption.SURVEY_LINK]: {
    optionType: SuggestionType.SURVEY_MODAL,
    optionText: 'Survey link',
  },
};

export const MyHRQueryLink = 'https://atoz.integ.amazon.com/myhr/search?query=';
