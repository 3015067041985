import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import defaultTheme from '@amzn/stencil-react-theme-default';
import React, { PropsWithChildren } from 'react';

interface IFederatedComponentWrapper {}

/**
 * Wrapper that encapsulates  federated components within StencilProvider along with Error Boundary
 * */
export const FederatedComponentWrapper: React.FC<PropsWithChildren<IFederatedComponentWrapper>> = ({
  children,
}): JSX.Element => {
  return (
    <ErrorBoundary fallbackUI={<>An error occurred, please try again later</>}>
      <StencilProvider theme={defaultTheme}>
        <React.Fragment>{children}</React.Fragment>
      </StencilProvider>
    </ErrorBoundary>
  );
};
