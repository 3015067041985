import { ExceptionDetail } from 'src/models/ExceptionDetail';
import { ErrorCodeToViewConfig, SERVICE_ERROR_CODE } from 'src/constants/ErrorCodes';

export const buildExceptionResponse = (error: any): ExceptionDetail => {
  //TODO: Add service specific mapping to get error code based on exception
  const errorCode = SERVICE_ERROR_CODE.DEFAULT_ERROR;
  const errorStatus = error?.response?.status ?? 500;
  const { message, isRetryable } = ErrorCodeToViewConfig[errorCode];

  return {
    errorCode,
    errorStatus,
    errorMessage: message,
    retryable: isRetryable,
  };
};
