import { createSvg, IconSize, SVGIconWithSizes } from '@amzn/stencil-react-components/icons';

export const IconVerifyCheckBoxFill = createSvg(
  'IconVerifyCheckBoxFill',
  new SVGIconWithSizes({
    [IconSize.ExtraSmall]: {
      paths: [
        // eslint-disable-next-line max-len
        'M16 8C16 6.8364 15.3388 5.8208 14.3736 5.308C14.6372 4.3036 14.3804 3.1868 13.5964 2.4032C13.0112 1.8176 12.24 1.5264 11.468 1.5264C11.2068 1.5264 10.9456 1.5596 10.6916 1.6264C10.1792 0.6612 9.1636 0 8 0C6.8364 0 5.8208 0.6612 5.308 1.6264C5.0536 1.5596 4.7928 1.5264 4.5316 1.5264C3.7596 1.5264 2.9888 1.818 2.4032 2.4032C1.6196 3.1868 1.3624 4.3032 1.626 5.308C0.6612 5.8208 0 6.8364 0 8C0 9.1636 0.6612 10.1792 1.6264 10.692C1.3628 11.6964 1.6196 12.8132 2.4036 13.5968C2.9888 14.182 3.76 14.4736 4.532 14.4736C4.7932 14.4736 5.0544 14.4404 5.3084 14.3736C5.8208 15.3388 6.8364 16 8 16C9.1636 16 10.1792 15.3388 10.692 14.3736C10.9464 14.4404 11.2072 14.4736 11.4684 14.4736C12.2404 14.4736 13.0112 14.182 13.5968 13.5968C14.3804 12.8132 14.6376 11.6968 14.374 10.692C15.3388 10.1792 16 9.1636 16 8Z M7.19997 11.5298L4.23438 8.56382L5.36557 7.43262L7.19997 9.26662L11.0344 5.43262L12.1656 6.56382L7.19997 11.5298Z',
      ],
      params: { height: '16', xmlns: 'http://www.w3.org/2000/svg' },
      height: 16,
      width: 16,
    },
    [IconSize.Small]: {
      paths: [
        // eslint-disable-next-line max-len
        'M16 8C16 6.8364 15.3388 5.8208 14.3736 5.308C14.6372 4.3036 14.3804 3.1868 13.5964 2.4032C13.0112 1.8176 12.24 1.5264 11.468 1.5264C11.2068 1.5264 10.9456 1.5596 10.6916 1.6264C10.1792 0.6612 9.1636 0 8 0C6.8364 0 5.8208 0.6612 5.308 1.6264C5.0536 1.5596 4.7928 1.5264 4.5316 1.5264C3.7596 1.5264 2.9888 1.818 2.4032 2.4032C1.6196 3.1868 1.3624 4.3032 1.626 5.308C0.6612 5.8208 0 6.8364 0 8C0 9.1636 0.6612 10.1792 1.6264 10.692C1.3628 11.6964 1.6196 12.8132 2.4036 13.5968C2.9888 14.182 3.76 14.4736 4.532 14.4736C4.7932 14.4736 5.0544 14.4404 5.3084 14.3736C5.8208 15.3388 6.8364 16 8 16C9.1636 16 10.1792 15.3388 10.692 14.3736C10.9464 14.4404 11.2072 14.4736 11.4684 14.4736C12.2404 14.4736 13.0112 14.182 13.5968 13.5968C14.3804 12.8132 14.6376 11.6968 14.374 10.692C15.3388 10.1792 16 9.1636 16 8Z M7.19997 11.5298L4.23438 8.56382L5.36557 7.43262L7.19997 9.26662L11.0344 5.43262L12.1656 6.56382L7.19997 11.5298Z',
      ],
      params: { height: '20', xmlns: 'http://www.w3.org/2000/svg' },
      height: 20,
      width: 20,
    },
    [IconSize.Medium]: {
      paths: [
        // eslint-disable-next-line max-len
        'M16 8C16 6.8364 15.3388 5.8208 14.3736 5.308C14.6372 4.3036 14.3804 3.1868 13.5964 2.4032C13.0112 1.8176 12.24 1.5264 11.468 1.5264C11.2068 1.5264 10.9456 1.5596 10.6916 1.6264C10.1792 0.6612 9.1636 0 8 0C6.8364 0 5.8208 0.6612 5.308 1.6264C5.0536 1.5596 4.7928 1.5264 4.5316 1.5264C3.7596 1.5264 2.9888 1.818 2.4032 2.4032C1.6196 3.1868 1.3624 4.3032 1.626 5.308C0.6612 5.8208 0 6.8364 0 8C0 9.1636 0.6612 10.1792 1.6264 10.692C1.3628 11.6964 1.6196 12.8132 2.4036 13.5968C2.9888 14.182 3.76 14.4736 4.532 14.4736C4.7932 14.4736 5.0544 14.4404 5.3084 14.3736C5.8208 15.3388 6.8364 16 8 16C9.1636 16 10.1792 15.3388 10.692 14.3736C10.9464 14.4404 11.2072 14.4736 11.4684 14.4736C12.2404 14.4736 13.0112 14.182 13.5968 13.5968C14.3804 12.8132 14.6376 11.6968 14.374 10.692C15.3388 10.1792 16 9.1636 16 8Z M7.19997 11.5298L4.23438 8.56382L5.36557 7.43262L7.19997 9.26662L11.0344 5.43262L12.1656 6.56382L7.19997 11.5298Z',
      ],
      params: { height: '24', xmlns: 'http://www.w3.org/2000/svg' },
      height: 24,
      width: 24,
    },
    [IconSize.Large]: {
      paths: [
        // eslint-disable-next-line max-len
        'M16 8C16 6.8364 15.3388 5.8208 14.3736 5.308C14.6372 4.3036 14.3804 3.1868 13.5964 2.4032C13.0112 1.8176 12.24 1.5264 11.468 1.5264C11.2068 1.5264 10.9456 1.5596 10.6916 1.6264C10.1792 0.6612 9.1636 0 8 0C6.8364 0 5.8208 0.6612 5.308 1.6264C5.0536 1.5596 4.7928 1.5264 4.5316 1.5264C3.7596 1.5264 2.9888 1.818 2.4032 2.4032C1.6196 3.1868 1.3624 4.3032 1.626 5.308C0.6612 5.8208 0 6.8364 0 8C0 9.1636 0.6612 10.1792 1.6264 10.692C1.3628 11.6964 1.6196 12.8132 2.4036 13.5968C2.9888 14.182 3.76 14.4736 4.532 14.4736C4.7932 14.4736 5.0544 14.4404 5.3084 14.3736C5.8208 15.3388 6.8364 16 8 16C9.1636 16 10.1792 15.3388 10.692 14.3736C10.9464 14.4404 11.2072 14.4736 11.4684 14.4736C12.2404 14.4736 13.0112 14.182 13.5968 13.5968C14.3804 12.8132 14.6376 11.6968 14.374 10.692C15.3388 10.1792 16 9.1636 16 8Z M7.19997 11.5298L4.23438 8.56382L5.36557 7.43262L7.19997 9.26662L11.0344 5.43262L12.1656 6.56382L7.19997 11.5298Z',
      ],
      params: { height: '32', xmlns: 'http://www.w3.org/2000/svg' },
      height: 32,
      width: 32,
    },
  }),
);
