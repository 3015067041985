import React from 'react';
import TimeHubAssistant from 'src/components/TimeHubAssistant/TimeHubAssistant';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {I18nextProvider} from "react-i18next";
import {setupI18nextLanguage} from "src/i18n/i18n";
import TimeHubSummary from "src/components/TimeHubSummary/TimeHubSummary";

export class App extends React.Component {
  render() {
    return (
      <I18nextProvider i18n={setupI18nextLanguage('en-US')}>
        <QueryClientProvider client={new QueryClient()}>
          <TimeHubSummary
            employeeInfo={{
              employeeId: '109367332',
              login: 'ojessher',
              preferredFirstName: 'Julia',
              preferredLastName: 'Johnson',
              preferredFullName: 'Julia Johnson',
              languageLocale: 'en-US',
              buildingCode: 'FTW1',
              countryCode: 'USA',
              timeZoneId: 'US/Central',
            }}
            identifier={'TimeCard'}
            onLoadingStatusChange={(d) => (console.log(d))}
          />
        </QueryClientProvider>
      </I18nextProvider>
    );
  }
}
