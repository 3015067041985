export const IDENTIFIER_TO_PROMPT_MAP: Map<string, string> = new Map<string, string>([
  [
    'HRSC TimeCard Summary',
    'Analyze my time and attendance data to provide actionable recommendations date by date for last week for improving time management and attendance, presenting a concise bullet-point summary of key time and attendance metrics such as UPT, Missed punches and Time Offs and generate an output in second person view in the specified format: Overview: one sentence summary along with most recent missed punch instance, upcoming timeoff request, only if aplied, the next schedule data timeings, the next payday date, and current PTO balance all in a sentence. Note that Paydays are fridays. Summary: important events and issue instances with dates and possible actions to take per issue pointed out, presenting the summary in a 3 to 6 bullet pointer paragraphs ignoring events without action items. Note: Give the Summary and Overview from a third person perspective. Do not use \"You\" to refer a person in the Overview and Summary, Instead use the word Associate. Example:  Associate have a missed punch on September 19th, no upcoming time off requests, Associate\'s next scheduled shift is on September 25th from 8:30 AM to 7:00 PM, the next payday is September 27th, and Associate\'s current PTO balance is 22 hours 10 minutes for personal time off and 45 hours 34 minutes for vacation time off. ',
  ],
  [
    'HRSC Deviations',
    'Analyze my time and attendance data to provide actionable recommendations for last week for improving time management and attendance, presenting a overview of metrics such as Missed punches and Time Offs and generate an output in second person view in the specified format: Overview: one sentence summary along with most recent missed punch instance, upcoming timeoff request only if aplied, the next schedule data timeings, the next payday date, and current PTO balance all in a sentence. Note that Paydays are fridays. Note: Give the Overview from a third person perspective. Do not use \\"You\\" to refer a person in the Overview. Example: Associate had 2 UPT infractions of 10 hours each on September 11 and September 19, resulting in your current UPT balance of 10 hours; Associate\'s next scheduled shift is from 8:30 AM to 7:00 PM on September 25; the next payday is September 27; and Associate\'s current PTO balance is 22 hours 10 minutes for personal time off and 45 hours 34 minutes for vacation time off.',
  ],
  [
    'TimeCard USA',
    'Analyze my time and attendance data to provide actionable recommendations date by date for last week for improving time management and attendance, presenting a concise bullet-point summary of key time and attendance metrics such as UPT, Missed punches and Time Offs and generate an output in second person view in the specified format: Overview: one sentence summary along with most recent missed punch instance, upcoming timeoff request, only if aplied, the next schedule data timeings, the next payday date, and current PTO balance all in a sentence. Note that Paydays are fridays. Summary: important events and issue instances with dates and possible actions to take per issue pointed out, presenting the summary in a 3 to 6 bullet pointer paragraphs ignoring events without action items. ScheduleInfo: one sentence summary of schedule and punch events any issues related to missed punch and missed schedule. UPTInfo: one sentence summary of UPT issues and UPT balance related issues, if any. ComplianceScore: Number between 0 and 100 indicating the compliance score based on WorkedHoursPolicy.txt policy. ScheduleWidgetPriorityScore: Number between 0 and 100 indicating the widget relevance based on issues related to punch and schedule. UPTWidgetPriorityScore: Number between 0 and 100 indicating the widget relevance based on issues related to UPT and UPT balance.',
  ],
  [
    'TimeCard AUS',
    'Analyze my time and attendance data to provide actionable recommendations date by date for last week for improving time management and attendance, presenting a concise bullet-point summary of key time and attendance metrics such as UPT, Missed punches and Time Offs and generate an output in second person view in the specified format: Overview: one sentence summary along with most recent missed punch instance, upcoming timeoff request, only if aplied, the next schedule data timeings, the next payday date, and current PTO balance all in a sentence. Note that Paydays are fridays. Summary: important events and issue instances with dates and possible actions to take per issue pointed out, presenting the summary in a 3 to 6 bullet pointer paragraphs ignoring events without action items. ScheduleInfo: one sentence summary of schedule and punch events any issues related to missed punch and missed schedule. UPTInfo: one sentence summary of UPT issues and UPT balance related issues, if any. ComplianceScore: Number between 0 and 100 indicating the compliance score based on WorkedHoursPolicy.txt policy. ScheduleWidgetPriorityScore: Number between 0 and 100 indicating the widget relevance based on issues related to punch and schedule. UPTWidgetPriorityScore: Number between 0 and 100 indicating the widget relevance based on issues related to UPT and UPT balance.',
  ],
  [
    'ManagerPortal',
    'Analyze my time and attendance data to provide actionable recommendations date by date for last week for improving time management and attendance, presenting a concise bullet-point summary of key time and attendance metrics such as UPT, Missed punches and Time Offs and generate an output in second person view in the specified format: Overview: one sentence summary along with most recent missed punch instance, upcoming timeoff request, only if aplied, the next schedule data timeings, the next payday date, and current PTO balance all in a sentence. Note that Paydays are fridays. Summary: important events and issue instances with dates and possible actions to take per issue pointed out, presenting the summary in a 3 to 6 bullet pointer paragraphs ignoring events without action items. Note: Give the Summary and Overview from a third person perspective. Do not use \"You\" to refer a person in the Overview and Summary, Instead use the word Associate. Example:  Associate have a missed punch on September 19th, no upcoming time off requests, Associate\'s next scheduled shift is on September 25th from 8:30 AM to 7:00 PM, the next payday is September 27th, and Associate\'s current PTO balance is 22 hours 10 minutes for personal time off and 45 hours 34 minutes for vacation time off. ',
  ],
]);
