import { STAGE } from 'src/constants/StageConfig';
import { OPERATORS_CONSTANTS } from 'src/constants/common';

export const ATOZ_CLIENT_ID = {
  ATOZ_TIMEOFF_SERVICE: 'ATOZ_TIMEOFF_SERVICE',
  ATOZ_MYHRGATEWAY_SERVICE: 'ATOZ_MYHRGATEWAY_SERVICE',
  MY_HR_SEGMENTATION: 'MyHR_Segmentation_v2',
  MY_HR_ROUTING_ENGINE: 'MyHR_RoutingEngine',
  MY_HR_LIVE_HELP: 'MyHR_LiveHelp',
};

export const ATOZ_API_GATEWAY_ENDPOINT: Record<STAGE, string> = {
  [STAGE.TEST]: 'https://atoz-api.integ.amazon.com',
  [STAGE.ALPHA]: 'https://atoz-api.integ.amazon.com',
  [STAGE.BETA]: 'https://atoz-api.integ.amazon.com',
  [STAGE.GAMMA]: 'https://atoz-api.integ.amazon.com',
  [STAGE.PROD]: 'https://atoz-api.amazon.work',
};


export const STAGE_TO_TIMEHUB_ASSISTANT_ENDPOINT: Record<STAGE, string> = {
  [STAGE.TEST]: 'https://alpha.timehub-assistant.pxt.amazon.dev',
  [STAGE.ALPHA]: 'https://alpha.timehub-assistant.pxt.amazon.dev',
  [STAGE.BETA]: 'https://beta.timehub-assistant.pxt.amazon.dev',
  [STAGE.GAMMA]: 'https://gamma.timehub-assistant.pxt.amazon.dev',
  [STAGE.PROD]: 'https://prod.timehub-assistant.pxt.amazon.dev',
};

export const QUERY_PARAMS = {
  EMPLOYEE_ID: 'employeeId',
  MY_HR_CLIENT_ID: 'x-myhr-client-id',
  OPERATION: 'operation',
  CLIENT_ID: 'clientId',
  PATH: 'path',
};

/**
 * Constant object to store endpoints for all APIs
 */
export const API_ENDPOINTS = {
  START_CHAT_CONTACT: '/v2/start-chat-contact',
  MY_HR_GATEWAY_SERVICE_ROUTER: '/router',
  ROUTING_CONFIGURATIONS: '/api/routing-configurations',
  LOG_URL: '/log'
};

export const ATOZ_API_OPERATIONS = {
  GET_EMPLOYEE_ATTRIBUTES: 'getEmployeeAttributes',
  GET_ROUTING_CONFIGURATIONS: 'GetRoutingConfigurations',
  START_VOICE_CONTACT: '/api/contact/voice',
};
