import React, { useState } from 'react';
import { ScheduleViewFeature, TaaConfigsContextProvider } from '@amzn/atoz-dashboard-taa-components';
import { DateTime } from 'luxon';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Col, PopupDatePicker, Row, Text } from '@amzn/atoz-web';
import { WidgetView, WidgetViewProps } from 'src/models/WidgetView';
import { getInitialSelectedDateFromWidgetInput } from 'src/utils/DateTimeUtils';
import { getTAAComponentConfig } from 'src/components/Widgets/WidgetUtils';

export interface ScheduleViewProps extends WidgetViewProps {}

const ScheduleView: WidgetView = ({ widgetInfo, employeeInfo }: ScheduleViewProps) => {
  const [date, setDate] = useState<DateTime>(
    getInitialSelectedDateFromWidgetInput(widgetInfo.widgetInputs.SelectedDate),
  );
  return (
    <QueryClientProvider client={new QueryClient()}>
      <TaaConfigsContextProvider taaConfigs={getTAAComponentConfig(employeeInfo)}>
        <Col>
          <Row justifyContent="space-between" padding={{ bottom: '12px', top: '12px' }} flexWrap="wrap">
            <Text fontSize={'T400'}>Schedule</Text>
            <PopupDatePicker
              id="schedule-view-date-picker"
              defaultValue={date.toFormat('MM/dd/yyyy')}
              value={date.toFormat('MM/dd/yyyy')}
              onChange={(newDate: string) => {
                setDate(DateTime.fromISO(newDate));
              }}
            />
          </Row>

          <ScheduleViewFeature
            selectedDate={date}
            isTemporalRoutingEnabled={false} // Get from Popstar
          />
        </Col>
      </TaaConfigsContextProvider>
    </QueryClientProvider>
  );
};

export default ScheduleView;
