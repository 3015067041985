import { METRIC_UNIT } from 'src/models/Metrics';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';

export function emitSessionLoadDurationMetrics(
  katalLogger: KatalLoggerClient,
  sessionStartTime: number,
  metricName: string,
  context: Record<string, any>,
): number {
  const endTime = Date.now();
  const duration = endTime - sessionStartTime;
  katalLogger.logEMFMetric(
    {
      metrics: [
        {
          metricName: metricName,
          metricUnit: METRIC_UNIT.MILLISECONDS,
          metricValue: duration,
        },
      ],
      dimensions: [],
    },
    context,
  );
  return endTime;
}
