import React, { useEffect, useState } from 'react';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { H2, H3, H4, Text } from '@amzn/stencil-react-components/text';

const getGreeting = () => {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return 'Good Morning';
  } else if (currentHour < 18) {
    return 'Good Afternoon';
  } else {
    return 'Good Evening';
  }
};

const DynamicSpinnerComponent = () => {
  const loadingMessages = [
    `${getGreeting()}! Welcome back to your timecard...`,
    'Hang on, We are curating your personalized timecard view...',
    'We are almost there...',
  ];

  const [currentMessage, setCurrentMessage] = useState(loadingMessages[0]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        const newIndex = prevIndex + 1 >= loadingMessages.length ? loadingMessages.length - 1 : prevIndex + 1;
        setCurrentMessage(loadingMessages[newIndex]);
        return newIndex;
      });
    }, 8000); // Change text every 8 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [loadingMessages]);

  return (
    <Col justifyContent={'center'} width={'100%'}>
      {/* <Spinner showText size={SpinnerSize.Medium} loadingText={currentMessage} fontSize={'T300'} /> */}
      <Text className="fliker-animation" fontSize={'T300'} textAlign="center">
        {currentMessage}
      </Text>
    </Col>
  );
};

export default DynamicSpinnerComponent;
