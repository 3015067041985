import React from 'react';
import { IErrorBanner } from 'src/components/ErrorBanner';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';

/**
 * Renders the Error components if  there is an error.
 */
export const ErrorBanner = ({ isError, message }: IErrorBanner) => {
  return (
    <>
      {isError && message.trim() && (
        <MessageBanner aria-live={'polite'} isDismissible={false} type={MessageBannerType.Error}>
          {message}
        </MessageBanner>
      )}
    </>
  );
};
