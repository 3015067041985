import React from 'react';
import { IErrorBoundaryProps, IErrorBoundaryState } from 'src/components/ErrorBoundary';
import { ErrorBanner } from 'src/components/ErrorBanner';
import { DISPLAY_STRINGS } from 'src/constants/DisplayMessages';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';
import { METRIC_UNIT } from 'src/models/Metrics';

/**
 * Error Boundary for handling errors in Components vended via Module Federation.
 * ref: https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 * */
export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  katalLogger: KatalLoggerClient = new KatalLoggerClient();

  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  /**
   * @function getDerivedStateFromError - function updates state to render Error UI. Update state so the next render will show the fallback UI.
   * @returns - returns updated state
   */
  static getDerivedStateFromError(): IErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.katalLogger.logEMFMetric(
      {
        metrics: [
          {
            metricName: 'ErrorCount',
            metricUnit: METRIC_UNIT.COUNT,
            metricValue: 1,
          },
        ],
        dimensions: [],
      },
      {
        errorMessage: error,
        errorInfo: errorInfo,
      },
    );
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallbackUI ?? <ErrorBanner isError={true} message={DISPLAY_STRINGS.SOMETHING_WENT_WRONG} />;
    }

    return this.props.children;
  }
}
