import { useEffect, useState } from 'react';

interface ChangingProgressProviderProps {
  values: number[];
  children: (value: number) => JSX.Element;
}

const ChangingProgressProvider = ({ values, children }: ChangingProgressProviderProps) => {
  const [valuesIndex, setValuesIndex] = useState(0);

  useEffect(() => {
    console.log('Setting value as this - ', valuesIndex);
    const timer = setInterval(() => {
      console.log('Updateed value index is this - ', valuesIndex);
      setValuesIndex((prevIndex) => {
        console.log('Updated value index is of prevIndex - ', prevIndex);
        if (prevIndex >= values.length - 1) {
          return values.length - 1;
        }
        return prevIndex + 1;
      });
    }, 10);

    return () => {
      console.log('Clearing interval');
      clearInterval(timer);
    }; // Cleanup the interval on component unmount
  }, [values.length]);

  return children(values[valuesIndex]);
};

export default ChangingProgressProvider;
