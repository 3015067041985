import React, { ReactElement } from 'react';
import { H2, H3, H4, P, Text } from '@amzn/stencil-react-components/text';
import { Link } from '@amzn/stencil-react-components/link';
import { CitationType } from '../models/ChatMessage';

export interface CitationInfo {
  policyText: ReactElement;
  label: string;
}
export const citationIdToDetailsMapping: Record<CitationType, CitationInfo> = {
  [CitationType.WORKED_HOURS_SCHEDULE_PUNCHES]: {
    label: 'Working Hours (Non-Exempt/Hourly) - US',
    policyText: (
      <>
        <P>
          Intended Audience: Hourly Employees, Managers of Hourly Employees, and HR. As Amazon strives to be the
          earth&apos;s best employer, your health and safety and accurate compensation are a priority. Amazon
          compensates time worked, and provides meal periods and rest breaks, in accordance with federal, state, and
          local regulations. This policy generally defines what constitutes paid working time for hourly (non-exempt)
          employees in situations that frequently arise. For situational questions or concerns, you should contact your
          manager or HR representative. To ensure full and accurate compensation, you are responsible for ensuring the
          accuracy and completeness of your own time records and may not misrepresent hours worked by either overstating
          or understating the actual hours worked.{' '}
        </P>
        <H2>Definitions</H2>
        <P>
          Meal period: An unpaid, work-free period of time in a duration as specified below. Off the clock work:
          Performing work and not recording the time on your timecard. On-call: You are not required to be at any
          specific location and you can use the time effectively for your own purposes, but you must be available by
          telephone, pager, or other electronic device during periods of time defined and designated as such by your
          manager or HR representative. Rest Break: A paid, work-free period of a minimum 10 minutes. Show-up Pay: Being
          paid the difference between time worked and 2 hours (or up to 4 hours if required by state law), as required
          by law and if you are involuntarily sent home due to lack of work or other similar reasons. Travel Time: A
          period spent transiting between work locations or the difference of time transiting from home to a work
          location other than your usual place of work as specified below.
        </P>
        <H2>Meal periods and rest breaks</H2>
        <P>
          Your safety and wellness are important to Amazon. Working hard also includes taking appropriate meal periods
          and rest breaks. Amazon provides the following unpaid meal periods and paid rest breaks depending on your
          shift and in accordance with federal and state law. All hourly employees are expected to take these meal
          periods and rest breaks (unless you are permitted to waive your meal period as described below). Amazon
          expects managers who supervise hourly employees to provide employees with meal periods and rest breaks in
          compliance with Amazon’s policies, federal and state law. Employees are responsible for taking all meal
          periods and rest breaks provided to them.
        </P>
        <H3>Meal periods</H3>
        <P>
          If you work more than five hours* in the workday you are required to take an uninterrupted, work-free
          30-minute meal period. This includes employees who work a single shift that is longer than 5 hours or who work
          multiple shifts in a single workday that total more than 5 hours. In accordance with applicable law, employees
          working fewer than 6-hours in a workday may be permitted to waive their meal period with management approval.
          Employees should contact their manager or HR representative for site-specific information. Below is a quick
          reference chart to help clarify the number of 30-minute meal periods provided under this policy*:
        </P>
        <table width="100%">
          <tbody>
            <tr>
              <th>
                <p>
                  <b>Hours worked/paid</b>
                </p>
              </th>
              <th>
                <p>
                  <b>Meal periods</b>
                </p>
              </th>
            </tr>
            <tr>
              <td width="291">
                <p>Less than 5 hours</p>
              </td>
              <td width="262">
                <p>0</p>
              </td>
            </tr>
            <tr>
              <td width="291">
                <p>*5.01 to 10.00 hours</p>
              </td>
              <td width="262">
                <p>1 (30-minute)</p>
              </td>
            </tr>
            <tr>
              <td width="291">
                <p>**10.01 to 12 hours</p>
              </td>
              <td width="262">
                <p>1 or 2, depending on the state (30-minute)</p>
              </td>
            </tr>
          </tbody>
        </table>
        <P>
          *Employees in California are provided a meal period if they work at least 5.0 hours in the workday. Employees
          in Connecticut, Delaware, Kentucky, and Oregon must start the meal period no earlier than the third hour into
          the employee’s shift in accordance with state law. In addition, employees working in California, Colorado,
          Kentucky, Illinois, New Hampshire, North Dakota, Oregon, and Washington must start the meal period no later
          than 5 hours into the employee's shift.
        </P>
        <ul>
          <li>
            Example #1: An employee working in California clocks in and starts working at 1:00 a.m. and is scheduled to
            work until 12:00 p.m. Unless validly waived, the employee will be provided 2 meal periods. The first meal
            period must start no later than 5:59 a.m. and the second meal period no later than 10:59 a.m.
          </li>
          <li>
            Example #2: An employee working in Kentucky clocks in and starts working at 7:00 a.m. The meal period must
            start no earlier than 10:00 a.m. and no later than 11:59 a.m.
          </li>
          <li>
            Example #3: An employee working in Washington clocks in and starts working at 9:00 a.m. The meal period must
            start no later than 1:59 p.m.
          </li>
        </ul>
        <P>
          The meal period for employees working in other states must generally start no later than 6 hours into an
          employee's shift.
        </P>
        <P>
          ** Employees in New Hampshire and Washington are entitled to a second meal period if they work more than 10
          hours in a workday. In California, employees are entitled to a second meal period if they work more than 10
          hours in a workday and the second meal period needs to be provided no later than the tenth hours of work.
          Employees in New York (contingent upon start and end times of shift and more than 6 hours worked), Nevada (if
          the shift is at least 16 hours), and Oregon (if the shift is at least 14 hours) may be entitled to a second
          meal period depending on the start and end times of their shifts. In other states, employees are typically not
          entitled to a second meal period.
        </P>
        <P>
          Meal periods are not paid and do not count towards hours worked. During meal periods, you must not perform any
          work nor are you expected to remain "on-call”. You are required to accurately record the start and end time of
          each meal period.
        </P>
        <P>
          An employee's meal period must not be interrupted, delayed, or missed except for exceptional circumstances. In
          the event a meal period is interrupted, delayed, or missed, you must consult with your manager or HR
          representative.
        </P>
        <P>
          Some employees may be required to take a longer meal period or a second meal period, depending on the length
          of the employee's shift and in accordance with state law.
        </P>
        <P>
          For example, employees working in California who work more than 10 hours are entitled to a second
          uninterrupted, work-free 30-minute meal period. If you work fewer than 12-hours in a workday you may be
          permitted to waive your second meal period with management approval.
        </P>
        <P>
          During the meal period you are free to leave the worksite. For your convenience Amazon provides optional break
          rooms at most locations. You should contact your manager or HR representative for site-specific information
          for anything meal period related.
        </P>
        <H3>On-duty meal period</H3>
        <P>
          You may not take an on-duty meal period unless required and approved by management. An on-duty paid meal
          period is only permitted when the nature of your work prevents you from being relieved of all duty, based on
          the necessary job duties, such as certain types of employees stationed alone at a remote site. You must record
          all on-duty meal periods as time worked.
        </P>
        <H3>Rest breaks</H3>
        <P>
          Amazon provides all employees a minimum 10-minute break for every 4 hours worked, or major fraction thereof,
          as follows:{' '}
        </P>
        <table>
          <tbody>
            <tr>
              <th>
                <p>
                  <b>Hours worked/paid</b>
                </p>
              </th>
              <th>
                <p>
                  <b>Rest breaks</b>
                </p>
              </th>
            </tr>
            <tr>
              <td width="168" valign="top">
                <p>Less than 3.50 hours</p>
              </td>
              <td>
                <p>None</p>
              </td>
            </tr>
            <tr>
              <td width="168" valign="top">
                <p>3.50 to 6 hours</p>
              </td>
              <td>
                <p>1 (10-min)</p>
              </td>
            </tr>
            <tr>
              <td width="168" valign="top">
                <p>6.01 to 10 hours</p>
              </td>
              <td>
                <p>2 (10-min)</p>
              </td>
            </tr>
            <tr>
              <td width="168" valign="top">
                <p>10.01 to 12 hours</p>
              </td>
              <td>
                <p>3 (10-min)</p>
              </td>
            </tr>
          </tbody>
        </table>
        <P>
          Note: Except in Colorado and Oregon, where employees receive 1, 10-minute paid rest break after 2 hours
          worked, and Washington where employees receive 1, 10-minute paid rest break after 3 hours worked.
        </P>
        <P>
          These rest breaks are paid and you should not clock in or out for them. Employees must be relieved of all
          their duties during rest breaks and you are not expected to remain “on-call” during rest breaks. Amazon
          provides unpaid meal periods and paid rest breaks depending on your shift and in accordance with federal and
          state law. All hourly employees are expected to take these meal periods and rest breaks (unless you are
          permitted to waive your meal period with management approval). In the event your rest break is interrupted,
          delayed, or missed, you must consult with your manager or HR representative.
        </P>
        <P>
          Rest breaks in California, Colorado, Kentucky, Maryland (retail business only), Minnesota, Nevada, Oregon,
          Vermont and Washington cannot be combined with other rest breaks or with meal periods, or be skipped to start
          work late or leave work early. In all other states, sites may allow you to combine paid rest breaks. Sites
          that allow employees to combine rest breaks must still comply with Amazon’s requirement that all employees are
          provided with rest break time of at least 10-minutes for every 4 hours of work or major fraction thereof, and
          must calculate the combined breaks to cover the appropriate time period. You must remain clocked in for rest
          breaks, even if they are combined.
        </P>
        <P>
          Transportation by Amazon (Sort Centers, Prime Now, and Delivery Stations) employees (except those working a
          shift between 2 and 3 hours in Colorado and Oregon) that flex down to a shift that is less than 3 hours in
          total do not need to be provided or take a rest break. In those businesses, you may not work more than 3
          consecutive hours without a rest break.
        </P>
        <P>
          During the rest break you are free to leave the worksite. For your convenience Amazon provides optional break
          rooms at most locations. You should contact your manager or HR representative for site-specific information
          for anything rest break related.
        </P>
        <H3>Additional breaks</H3>
        <P>
          Amazon recognizes that you may need additional, reasonable break time based on medical restrictions, religious
          accommodation, or needs of mothers who breastfeed. When possible, you should use your regularly scheduled paid
          rest breaks and unpaid meal period time for these reasons. In certain states, nursing mothers may be entitled
          to additional break time for purposes of expressing milk or lactation. If additional break time is needed,
          discuss your needs with your HR representative.
        </P>
        <H2>Travel time</H2>
        <P>
          Hourly employees may be asked to travel for work purposes. The following principles apply in determining if
          time spent in travel is paid time.
        </P>
        <H3>Home to work</H3>
        <P>
          The time an employee spends commuting to and from home on regular workdays is not counted as hours worked.
          However, if an employee is required to perform work while traveling, then the time spent performing such work
          must always be reported as working time.
        </P>
        <ul>
          <li>
            Example 1: Michael leaves his house at 8:00 a.m. and arrives at work to start his workday at 9:00 a.m.
            Michael will not be paid for the one hour of commuting time, and the calculation of his work hours will
            begin at 9:00 a.m.
          </li>
          <li>
            Example 2: Michelle takes a ferry to and from work each morning leaving at 7:00 a.m. and arriving at 8:00
            a.m. to start her workday. One day, she was asked to call in for a meeting with her manager during the hour
            she was on the ferry. Michelle will be compensated for the one hour she spent on the conference call as she
            was engaged in a work-related activity.
          </li>
        </ul>
        <H3>Travel away from regular worksite</H3>
        <P>When employees travel for business, they will be compensated for all travel time.</P>
        <P>
          Example: Sara's regular work schedule is 8:00 a.m. to 5:00 p.m. She took a flight at 6:00 p.m. to attend a
          business meeting and her flight landed at 10:00 p.m. She will be compensated for the 4 hours she spent on the
          plane, even though it occurred outside her normal business hours.
        </P>
        <P>
          Leaving from home: If employees leave from home to work somewhere other than their regular work location, the
          time they would normally spend commuting from home to their regular work location will not count towards hours
          worked.
        </P>
        <P>
          Example: Michelle typically commutes 30 minutes to work and is scheduled to work from 8:00 a.m. to 5:00 p.m.
          One day, she is asked to drive to another city for a training session. She leaves at 6:00 a.m. and drives for
          2 hours, arriving at the out-of-town location at 8:00 a.m. The training session ends at 5:00 p.m. and she
          spends 2 hours driving home. In addition to her regular workday, Michelle should record 3 hours of travel time
          (that is, 2 hours each way minus normal commute time equals one and a half hours of travel time each way).
        </P>
        <P>
          Leaving from Amazon facility: If employees travel away from their regular worksite, and leave from and return
          to an Amazon facility, they will be paid for the total time traveled.
        </P>
        <P>
          Example: Michelle typically commutes 30 minutes to work and is scheduled to work from 8:00 a.m. to 5:00 p.m.
          One day, she is asked to drive from her Amazon Facility to another city for a training session. She leaves
          from the Amazon facility at 5:00 p.m. and drives for one hour, arriving at the out-of-town location at 6:00
          p.m. The training session ends at 7:00 p.m. and she spends one and a half hours driving home. In addition to
          her regular workday, Michelle should record 2 hours of travel time (that is, one hour to the training session,
          and one and one-half hours driving home, minus the 30-minute normal commute home).
        </P>
        <P>
          Travel time starts when the employee departs for their intended destination (minus normal commuting time if
          applicable) until the time they reach their destination, such as a hotel or meeting location. This time
          includes any time checking in at the airport, waiting for delayed flights, sitting on a plane, bus, train, or
          being delayed due to something outside of the employee's control.
        </P>
        <P>
          All employees are responsible for correctly reporting all travel time. When an employee travels between 2 or
          more time zones, the time zone employed with the point of departure should be used to track time. Travel time
          will be paid at the employee's regular hourly rate and will be included in hours worked for proper overtime
          calculations. Where possible, business travel should occur within regularly scheduled work hours.
        </P>
        <P>
          Time spent taking a break from travel in order to sleep or engage in purely personal pursuits not connected
          with traveling or making necessary travel connections (such as spending an extra day in a city before the
          start or following the conclusion of a conference in order to sightsee), is not compensable. In addition, meal
          periods taken while traveling are not hours worked as long as the employee is relieved of all work duties and
          the meal period is uninterrupted and at least 30 minutes in length.
        </P>
        <P>
          All employees must review travel arrangements with their manager prior to departure and reservations being
          made. Travel arrangements must comply with Amazon's Travel Policy.
        </P>
        <H2>“Off the Clock” work not allowed</H2>
        <P>
          Hourly employees are responsible for accurately reporting all hours worked, whether at an Amazon building or
          off-site. No one may allow or ask you to perform work “off the clock” without being paid. If you feel that you
          are being pressured in any way to do so and you are not being properly paid for all of your work, you must
          inform an HR representative or report the conduct through the open door policy, which can be found in the
          Owner's Manual and Guide to Employment.
        </P>
        <H2>Off-site work</H2>
        <P>
          Hourly employees who are regularly scheduled to work at an Amazon site should not perform work off-site unless
          required or approved by management. Such work time may include training programs, meetings, or work-related
          communications or work performed on laptops, cell phones or other electronic devices. Failure to accurately
          report all off-site working time may subject you or your managers to corrective action.
        </P>
        <H2>Working from home</H2>
        <H3>
          Hourly employees who work from home are responsible for reporting all hours worked, whether from their home
          workspace or for training or activities at any Amazon site. If you are regularly scheduled to work from an
          approved home workspace you may not perform work at any other location unless required or approved by
          management.
        </H3>
        <H3>On-call time</H3>
        <P>
          The on-call policy ensures that employees meet Amazon’s after-hours needs in a reliable and timely way. You
          are not expected to be available by telephone, log on to Amazon systems, or otherwise “check in” in any way
          outside of your designated “on-call” time. Management must communicate reasonable expectations relating to
          on-call time (including a reasonable response time) prior to scheduling you in this capacity.
        </P>
        <P>
          If you are called upon to work, you will be paid your regular hourly rate (or overtime rate if applicable) and
          hours worked will be determined as follows, unless applicable state or local law requires otherwise:
        </P>
        <ul>
          <li>
            Employees who work off-site for 15 minutes or less will be paid for and should report 15 minutes of working
            time.
          </li>
          <li>Employees who work off-site for more than 15 minutes, will be paid for all time actually worked.</li>
          <li>
            On-call employees who report to an Amazon facility to work, will be paid a minimum of 2 hours of time plus
            any time worked beyond that.
          </li>
          <ul>
            <li>
              Example 1: While serving in a dedicated on-call capacity, Juan received a phone call from his manager. The
              phone conversation lasted 10 minutes in length. Even though Juan only worked for 10 minutes, he should
              record punches of 15 minutes.
            </li>
            <li>
              Example 2: While serving in a dedicated on-call capacity, Jared received a phone call to report to an
              Amazon facility to work. He arrives to work and punches in at the facility at 6:30 p.m. and punches out
              when leaving at 7:00 p.m. Jared’s manager or HR representative should submit an adjustment request for the
              other one and a half hours minimum because he reported to an Amazon facility.
            </li>
            <li>
              Example 3: While serving in a dedicated on-call capacity, Diane received a phone call to report to an
              Amazon facility to work. She arrives to work and punches in at the facility at 6:30 p.m. and punches out
              when leaving at 9:00 p.m. Diane will be paid for 2 and a half hours.
            </li>
          </ul>
        </ul>
        <P>
          When called upon to work, employees must be able to work in accordance with Amazon's Code of Business Conduct
          and Ethics.
        </P>
        <H2>Show-up pay</H2>
        <P>
          Two hours of “show-up pay” is paid when hourly employees are required to report to work but are involuntarily
          sent home due to lack of work or other similar reason when less than 2 hours is worked. Show-up pay is when no
          work is performed and is not included in total hours worked for overtime calculations.
        </P>
        <P>
          Example: Susan was scheduled to work from 6:00 a.m. to 4:30 p.m. Susan arrived at work at 6:00 a.m. but, due
          to lack of work, she was involuntarily sent home at 7:00 a.m. Even though Susan only worked 1 hour, she will
          be paid for 2 hours. However, only the first hour must count toward hours worked when determining whether
          overtime is due.
        </P>
        <P>
          Some states offer more generous “show-up pay” requirements and in those cases, you will be paid consistent
          with all applicable laws and regulations. For example, in California, “show up pay” is paid at your regular
          rate of pay (not the straight base rate) and you may be entitled to more than 2 hours of pay for reporting to
          work, regardless of whether you report to Amazon facility or another worksite. You should contact your manager
          or HR representative for site-specific information.
        </P>
        <H2>Limits on time worked</H2>
        <P>
          Consumer employees will not be asked, nor may you volunteer, to work more than 12 hours per day, 60 hours per
          week, or more than 6 days in a row, regardless of shift. Any requests to work beyond these guidelines requires
          the approval of the business director and will be reviewed on an individual basis.
        </P>
      </>
    ),
  },
  [CitationType.UPT_POLICY]: {
    label: 'UPT Policy',
    policyText: (
      <>
        <H2>Purpose</H2>
        <P>
          Regular and reliable attendance is a key part of your job at Amazon. Amazon provides excused time off options
          that allow employees to plan for absences and manage unplanned issues. This policy describes what attendance
          actions are expected of you.{' '}
        </P>
        <H2>Applicability</H2>
        <P>
          This policy applies in the US. It applies to all Worldwide Amazon Stores businesses, excluding Customer
          Service. This policy applies to “Core” hourly employees. “Core” employees are on regular, recurring schedules,
          with limited ability to drop or change shifts. If you are a Customer Service employee, visit{' '}
          <Link>Policy: Attendance (CS) - US</Link> for your policy.
        </P>
        <H2>Eligibility</H2>
        <P>
          Eligibility for unpaid time (UPT) depends on employee class. These classes of employees are eligible for UPT:
          <ul>
            <li>Regular Full-time Employees (Class F).</li>
            <li>Part-time Employees (Class H and Class Q).</li>
            <li>Reduced-time Employees (Class R).</li>
            <li>Seasonal Employees (Class M and Class S).</li>
          </ul>
          If you do not know if this policy applies to you, contact your manager or HR partner. To find your employment
          type on the AtoZ mobile app, go to More {'>'} My profile and job details and scroll down to Employment type.
        </P>
        <H2>Overview</H2>
        <P>
          Your attendance is critical to maintaining our customers’ experience. Our attendance policy provides limited
          unpaid time (UPT) to cover unexpected absences. UPT is only one of several time off options available to you.
          You can see your UPT balance in A to Z. Amazon uses A to Z to send you notifications when UPT is used. You are
          responsible for reviewing your time off balance and timecard for accuracy. Responsible attendance means
          clocking in/out and arriving to your scheduled shift on time. If you need to miss part or all of your shift,
          report it as far in advance as possible. This helps your manager plan correctly so our teams can deliver for
          customers.
        </P>
        <H2>Absence reporting</H2>
        <P>
          Report an absence at least 2 hours before shift start using the Inform of Lateness or Absence feature on the
          ‘Time off and leave’ page in A to Z. You can use UPT or paid time-off to cover your missed shift.
        </P>
        <H3>Exceptions</H3>
        <P>
          From time to time, unexpected emergencies like illness, injury, or personal events may stop you from providing
          the 2-hour notice. Amazon provides you with paid time off for illness, injury, and other emergencies, as well
          as Leave of Absence and Accommodation options. Check the{' '}
          <Text textDecoration={'underline'}>Time off, Leave of Absence, and Accommodation options</Text> section of
          this policy for details.
        </P>
        <H2>Accruing UPT</H2>
        <P>
          You receive 10 hours of UPT when hired. After that, you receive UPT when you work. You will receive 5 minutes
          of UPT for every 1 hour worked. You will also receive UPT for:
          <ul>
            <li>Working Mandatory Extra Time (MET).</li>
            <li>Working Voluntary Extra Time (VET).</li>
            <li>Accepting Voluntary Time Off (VTO).</li>
            <li>Having Mandatory Time Off (MTO; Lack of Work) applied.</li>
          </ul>
          <Text fontWeight={'bold'}>Example 1:</Text> If you work 40 hours in a week, you will receive 200 minutes (3
          hours and 20 minutes) of UPT per week.
          <Text fontWeight={'bold'}>Example 2:</Text> If you work 20 hours in a week, you will receive 100 minutes (1
          hour and 40 minutes) of UPT per week.
          <Text fontWeight={'bold'}>Example 3:</Text> If you accept 1 hour of VTO, you will receive 5 minutes of UPT for
          the 1 hour of VTO.
        </P>
        <H3>UPT balance cap and carryover</H3>
        <P>
          The UPT balance cap is 80 hours. Once you reach 80 hours, you will not receive any more UPT until you use some
          and fall below the cap. You can roll over up to 80 hours of unused UPT into the next year. Your UPT balance
          will update at the end of each day. Contact your manager or HR if you have questions about your UPT balance.
        </P>
        <H2>Using UPT</H2>
        <P>
          UPT can cover unexpected absences and is applied in 15-minute increments. UPT is used automatically when you
          miss any part of your scheduled shift, unless you have already applied another time off.
          <Text fontWeight={'bold'}>Example:</Text> If you arrive 12 minutes late and this time is not already covered,
          15 minutes of UPT will be used. This is because UPT is applied in 15-minute increments.
        </P>
        <H3>Exceptions</H3>
        <P>
          From time to time, unexpected emergencies like illness, injury, or personal events may stop you from being at
          work as scheduled. Amazon provides you paid time off for illness, injury, and other emergencies, as well as
          Leave of Absence and Accommodation options. UPT will not be deducted if a paid time off option, Leave of
          Absence, or Accommodation applies. Check the Time off, Leave of Absence, and Accommodation options section of
          this policy for details. Contact your manager or HR if you believe UPT was deducted in error.
        </P>
        <H3>What if my UPT balance is negative?</H3>
        <P>
          If you use more UPT than you have, your UPT balance will be negative. If “US Unpaid Time Overdraft” is on your
          pay records, you have a negative UPT balance. Amazon will automatically use your personal time off to cover
          your negative UPT balance. If you do not have UPT or other time off options to cover your missed shift, Amazon
          will review your employment in accordance with its attendance policies.
        </P>
        <H2>Time off, Leave of Absence, and Accommodation options</H2>
        <P>
          Amazon does not deduct UPT when absences are covered by paid time off, one of our leave of absence (LOA)
          policies, Accommodations Policy, or applicable law. If your paid time off has exhausted or is unavailable, you
          may still qualify for an LOA or Accommodation to cover your absence. LOA and Accommodations must be approved
          through our central leave or accommodations process. Medical certification from a health care provider may be
          required. Time off, LOA, and Accommodation options may vary based on where you live and work. Examples of
          reasons for which you may qualify for time off options include but are not limited to:
          <ul>
            <li>
              Sickness, injury, or disability, including time to rest and recover, and attend doctor’s appointments;
            </li>
            <li>Pregnancy, including time to rest, recover, and attend doctor’s appointments;</li>
            <li>Disability accommodations;</li>
            <li>
              Caring for a family member who is seriously sick, injured, or dealing with a pregnancy-related issue;
            </li>
            <li>Domestic violence, sexual assault, or stalking;</li>
            <li>Jury duty or witness appearance;</li>
            <li>Bereavement;</li>
            <li>Voting;</li>
            <li>Preparing for your or a family member’s military service; and,</li>
            <li>Any other applicable law.</li>
          </ul>
          You can apply for the time off option ahead of time or within the same workweek, unless you have an
          explanation which Amazon will accept as a reason for not applying for time off in advance. A workweek begins
          on Sunday and ends on Saturday. While your time off requests are being reviewed, you may see UPT deductions in
          AtoZ. This will adjust after your time off is approved. You can learn more about leave options in these ways:
          <ul>
            <li>Visit MyHR</li>
            <li>Visit the DLS Portal on the AtoZ phone app:</li>
            <ul>
              <li>Choose More at the bottom of the screen, then Tools and Resources.</li>
            </ul>
            <li>
              Check out a summary of LOA options on the AtoZ phone app. If you cannot access AtoZ, ask your manager or
              HR representative to follow these steps and print this summary for you.
            </li>
            <ul>
              <li>From a computer, choose Resources at the bottom of the screen.</li>
              <li>Choose Learn about my leave options.</li>
            </ul>
            <li>
              Visit Leave and Accommodations - US, Policy: Accommodation- US, and Policy: Paid Time Off by State- US on
              Inside Amazon.
            </li>
          </ul>
        </P>
        <H2>Additional information</H2>
        <P>
          Have questions or concerns about your UPT? We encourage you to use the Amazon A to Z app for self-service, 24
          hours a day, 7 days a week support. You can also reach out to your manager or HR representative. They are
          available to help you address any issues that may be preventing you from attending your scheduled shift or
          that may be impeding your ability to use other time off options. The goal is to match you with resources to
          improve your particular situation and work together to put you in the best position possible for successful
          employment with Amazon. This policy will be applied in a way that is consistent with applicable laws,
          including those laws that pertain to sick and safe time, leave of absence, and accommodation. This policy may
          change from time to time, with or without advance notice. Amazon reserves the right to depart from the policy
          when deemed appropriate.
        </P>
      </>
    ),
  },
  [CitationType.TIME_OFF_GENERAL_POLICY]: {
    label: 'Time off General Policy',
    policyText: (
      <>
        <H2>Applicability</H2>
        <P>This page details the time off benefits that apply to employees that work in Washington.</P>
        <H2>Eligibility</H2>
        <P>
          To find out which Time Off policies you are eligible for, you need to know your employee class. Check your
          employee class in A to Z(Opens in a new tab). Go to You {'>'} Job details tab for you or your team.
        </P>
        <H2>Paid personal time</H2>
        <P>
          Amazon provides Paid Personal Time (PPT) to eligible employees who work in Washington. PPT can be used for any
          purpose, including sick time or for your own personal business. Eligible employees will earn PPT in an initial
          annual grant,and then during each pay period up to a calendar year maximum. Up to 48 hours of unused PPT will
          be carried forward to the next calendar year.
        </P>
        <H3>Eligibility</H3>
        <table width="100%">
          <caption>Information on policy eligibility by class.</caption>
          <tbody>
            <tr>
              <th>
                <p>Eligible</p>
              </th>
              <th>
                <p>Not eligible</p>
              </th>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>Class F – Full-time</li>
                  <li>Class R – Reduced-time</li>
                  <li>Class H – Part-time</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Class X – Flex-time</li>
                  <li>Class Q – Field Part-time</li>
                  <li>Class I – Intern</li>
                  <li>Class M – Internal Staffing Solutions</li>
                  <li>Class S – Seasonal/Short-term</li>
                  <li>Independent Contractors (Classes T, V, W)</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <H3>Earning paid personal time</H3>
        <P>
          You will be given an initial annual grant of Paid Personal Time each year, and you will earn additional time
          each pay period thereafter until you reach your calendar year earning maximum. The first initial annual grant
          you are given will be on your date of hire. After that, initial annual grants will be given on your first pay
          date of the year.
        </P>
        <H3>Initial Annual Grants</H3>
        <ul>
          <li>Class F (Full-time): 10 hours</li>
          <li>Class R (Reduced-time): 7.5 hours</li>
          <li>Class H (Part-time): 5 hours</li>
        </ul>
        <H3>Annual Earning Limits</H3>
        <ul>
          <li>Class F (Full-time): 48 hours</li>
          <li>Class R (Reduced-time): 36 hours</li>
          <li>Class H (Part-time): 24 hours</li>
        </ul>
        <P>
          Ineligible employees who are converted to an eligible employee class will begin earning PPT on the pay date
          following the day they join the eligible employee class.
          <Text fontWeight={'bold'}>Example 1</Text>
          John, a monthly-paid Class F (Full-time) employee, receives an initial annual grant of 10 hours of PPT. John
          also earns 8 hours of PPT per month in January, February, March, and April. In May, John earns 6 hours,
          bringing the total for the year to 48 hours (the maximum for his class). For the remainder of the calendar
          year, John does not earn more PPT.
          <Text fontWeight={'bold'}>Example 2</Text>
          Praveen is a Class X (Flex-time) employee and is not eligible under the Paid Personal Time policy. In October,
          Praveen changes to a Class F (Full-time) employee. Because Praveen has changed to a Class F employee, they are
          now eligible, and on their next pay period Praveen is given their initial annual grant (10 hours of PPT) and
          begins earning PPT. PPT is earned at the end of each pay period. A chart of earning rates by pay frequency is
          set out in Appendix A.
        </P>
        <H3>Paid personal time carry over</H3>
        <P>
          You may carry over earned, unused Paid Personal Time from one calendar year to the next up to the carry over
          limit. If you have more PPT than the carry over limit at the end of the year, the amount that exceeds the
          limit will be applied to your Washington Sick and Safe Time carry over limit. Any PPT in excess of those
          amounts will be forfeited.
        </P>
        <H3>Carry over limits</H3>
        <P>
          <ul>
            <li>Paid personal time</li>
            <ul>
              <li>Class F (Full-time): 48 hours</li>
              <li>Class R (Reduced-time): 36 hours</li>
              <li>Class H (Part-time): 24 hours</li>
            </ul>
            <li>Sick and safe time</li>
            <ul>
              <li>Class F (Full-time): 24 hours</li>
              <li>Class R (Reduced-time): 36 hours</li>
              <li>Class H (Part-time): 48 hours</li>
            </ul>
            <P>Example</P>
            <P>
              Neva is a Class F employee and ends the year with 80 hours of PPT and 20 hours of sick time. 48 hours of
              PPT will be carried over to next year and will be available as PPT, 20 hours of sick time will be carried
              over to next year and will be available as sick time, and an additional 4 hours of PPT will be carried
              over to next year and will be converted to sick time. Neva will begin the next year with 48 hours of PPT
              and 24 hours of sick time. The remaining 28 hours of PPT will be forfeited.
            </P>
          </ul>
        </P>
        <H3>Paid personal time balance cap</H3>
        <P>
          Once you reach your Paid Personal Time balance cap, you will not earn any additional PPT until you use some
          and fall below the cap.
        </P>
        <H3>Paid Personal Time balance cap</H3>
        <ul>
          <li>Class F (Full-time): 96 hours</li>
          <li>Class R (Reduced-time): 72 hours</li>
          <li>Class H (Part-time): 36 hours</li>
        </ul>
        <H3>Using paid personal time</H3>
        <P>
          Schedule use in advance when possible Whenever possible, schedule Paid Personal Time in advance. The way you
          schedule PPT may differ depending on what timekeeping system your team uses. If you are unsure how to schedule
          PPT, ask your manager, your HR team, or visit My HR. If you are not able to schedule PPT in advance because
          you are using it for an unforeseeable event or family emergency, you still must follow Amazon’s absence
          reporting procedures, unless you cannot reasonably do so. If you cannot follow the absence reporting
          procedures due to circumstances out of your control, you should notify your manager or HR as soon as you are
          able. If you are unable to provide notice personally, notice may be provided by someone else (for example,
          spouse, domestic partner, adult family member, or other responsible party). If you take more than 3
          consecutive days off without advance notice, your HR team may require an explanation or reasonable
          documentation from you explaining why you could not provide advance notice of your absence. Do not provide
          documentation specifying the nature of a medical condition. Failure to record paid personal time may lead to
          discipline You are required to report the PPT that you use. If you take time off, and fail to record it, you
          may be subject to discipline. Discipline can include actions up to and including termination, as permitted by
          law. Paid personal time increments and future- and post-dated use PPT can be used in increments that match
          what is available under the timekeeping system you use. For example, if your timekeeping system tracks time in
          6-minute increments, the smallest possible amount of PPT you can use is 6 minutes. PPT will be deducted from
          your balance when the date you are using it has passed.
        </P>
        <H2>Vacation time</H2>
        <P>
          Amazon provides Vacation Time to eligible employees who work in Washington. Amazon believes that eligible
          employees should earn and take vacation on a regular basis for their personal well-being and continued high
          performance. Eligible employees will earn Vacation Time during each pay period up to a calendar year maximum.
        </P>
        <H3>Eligibility</H3>
        <table width="100%">
          <caption>Information on policy eligibility by class.</caption>
          <tbody>
            <tr>
              <th>
                <p>Eligible</p>
              </th>
              <th>
                <p>Not eligible</p>
              </th>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>Class F - Full-time</li>
                  <li>Class R - Reduced-time</li>
                  <li>Class H - Part-time</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Class X - Flex-time</li>
                  <li>Class Q - Field Part-time</li>
                  <li>Class I - Intern</li>
                  <li>Class M - Internal Staffing Solutions</li>
                  <li>Class S - Seasonal/Short-term</li>
                  <li>Independent Contractors (Classes T, V, W)</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <H3>Earning vacation time</H3>
        <P>
          Eligible employees earn Vacation Time beginning on their date of hire. Ineligible employees who are converted
          to an eligible employee class will begin earning vacation on the pay date following the day they join the
          eligible employee class. The amount of Vacation Time you earn each year is based on your employee class,
          whether you are a salaried or hourly employee, and your continuous completed years of service with Amazon.
          Service time as a temporary employee does not count toward this number. The following charts show how much
          vacation time you can earn each calendar year:
        </P>
        <H3>Hourly Employees</H3>
        <table width="100%">
          <caption>Hourly employee calendar year earnings by completed years of service and class.</caption>
          <tbody>
            <tr>
              <th>
                <p>Completed Years of Service</p>
              </th>
              <th>
                <p>Class F</p>
                <p>Full-Time</p>
              </th>
              <th>
                <p>Class R</p>
                <p>Reduced-Time</p>
              </th>
              <th>
                <p>Class H</p>
                <p>Part-Time</p>
              </th>
            </tr>
            <tr>
              <th>
                <p>0</p>
              </th>
              <td>
                <p>40 hours</p>
              </td>
              <td>
                <p>30 hours</p>
              </td>
              <td>
                <p>20 hours</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>1</p>
              </th>
              <td>
                <p>80 hours</p>
              </td>
              <td>
                <p>60 hours</p>
              </td>
              <td>
                <p>40 hours</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>2</p>
              </th>
              <td>
                <p>88 hours</p>
              </td>
              <td>
                <p>66 hours</p>
              </td>
              <td>
                <p>44 hours</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>3</p>
              </th>
              <td>
                <p>96 hours</p>
              </td>
              <td>
                <p>72 hours</p>
              </td>
              <td>
                <p>48 hours</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>4</p>
              </th>
              <td>
                <p>104 hours</p>
              </td>
              <td>
                <p>78 hours</p>
              </td>
              <td>
                <p>52 hours</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>5</p>
              </th>
              <td>
                <p>112 hours</p>
              </td>
              <td>
                <p>84 hours</p>
              </td>
              <td>
                <p>56 hours</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>6+</p>
              </th>
              <td>
                <p>120 hours</p>
              </td>
              <td>
                <p>90 hours</p>
              </td>
              <td>
                <p>60 hours</p>
              </td>
            </tr>
          </tbody>
        </table>
        <H3>Salaried employees</H3>
        <table width="100%">
          <caption>Salaried employee calendar year earnings by completed years of service and class.</caption>
          <tbody>
            <tr>
              <th>
                <p>Completed Years of Service</p>
              </th>
              <th>
                <p>Class F</p>
                <p>Full-Time</p>
              </th>
              <th>
                <p>Class R</p>
                <p>Reduced-Time</p>
              </th>
              <th>
                <p>Class H</p>
                <p>Part-Time</p>
              </th>
            </tr>
            <tr>
              <th>
                <p>0</p>
              </th>
              <td>
                <p>80 hours</p>
              </td>
              <td>
                <p>60 hours</p>
              </td>
              <td>
                <p>40 hours</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>1</p>
              </th>
              <td>
                <p>120 hours</p>
              </td>
              <td>
                <p>90 hours</p>
              </td>
              <td>
                <p>60 hours</p>
              </td>
            </tr>
            <tr>
              <th>
                <p>6+</p>
              </th>
              <td>
                <p>160 hours</p>
              </td>
              <td>
                <p>120 hours</p>
              </td>
              <td>
                <p>80 hours</p>
              </td>
            </tr>
          </tbody>
        </table>
        <P>
          Vacation is earned at the end of each pay period. A chart of earning rates by pay frequency is set out below
          in Appendix B.
        </P>
        <H3>Vacation carryover</H3>
        <P>
          Any unused Vacation Time at the end of the year will stay in your vacation balance and will be available for
          use in the next year.
        </P>
        <H3>Vacation time balance cap</H3>
        <P>
          You may have a Vacation Time balance of up to 160 hours. After your balance reaches 160 hours, you will stop
          earning vacation until your balance is below 160.
        </P>
        <H3>Using vacation time</H3>
        <H4>Schedule use in advance</H4>
        <P>Your manager must approve all Vacation Time requests in advance.</P>
        <P>
          The way you schedule vacation may differ depending on what timekeeping system your team uses. If you are
          unsure how to schedule vacation ask your manager, your HR team, or visit My HR.
        </P>
        <H4>Failure to record vacation time may lead to discipline</H4>
        <P>
          You are required to report the vacation that you use. If you take time off, and fail to record it, you may be
          subject to discipline. Discipline can include actions up to and including termination, as permitted by law.
        </P>
        <H4>Vacation time increments and future- and post-dated use</H4>
        <P>
          Vacation can be used in increments that match what is available under the timekeeping system you use. For
          example, if your timekeeping system tracks time in 6-minute increments, the smallest possible amount of
          vacation you can use is 6 minutes. Vacation will be deducted from your balance when the date you are using it
          has passed.
        </P>
        <H2>Sick and safe time</H2>
        <P>
          Amazon provides Sick and Safe Time to eligible employees working in Washington State. This policy supplements
          the Washington Paid Personal Time Policy. Paid Personal Time can be used in the event of illness or other
          personal business, including for Sick and Safe Time Purposes. For purposes of the City of Seattle Paid Sick
          and Safe Time Ordinance (SMC 14.16), Amazon is a Tier 3 employer. This policy complies with the Washington
          Paid Sick Leave and the Tacoma Paid Sick Leave requirements in addition to the Seattle Paid Sick and Safe Time
          Ordinance.
        </P>
        <H3>Eligibility</H3>
        <table width="100%">
          <caption>Information on policy eligibility by class.</caption>
          <tbody>
            <tr>
              <th>
                <p>Eligible</p>
              </th>
              <th>
                <p>Not eligible</p>
              </th>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>Class F - Full-time</li>
                  <li>Class R - Reduced-time</li>
                  <li>Class H - Part-time</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Class X - Flex-time</li>
                  <li>Class Q - Field Part-time</li>
                  <li>Class I - Intern</li>
                  <li>Class M - Internal Staffing Solutions</li>
                  <li>Class S - Seasonal/Short-term</li>
                  <li>Independent Contractors (Classes T, V, W)</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <H3>Earning sick and safe time</H3>
        <P>
          Salaried employees earn a fixed amount of sick time each year. Hourly employees will typically earn the same
          amount as a salaried employee, but hourly employees who work more hours than their regularly-scheduled work
          week may earn additional sick time. Actual sick time earnings for hourly employees will be corrected each pay
          period based on actual hours worked.
        </P>
        <H3>Annual Sick Time earnings</H3>
        <ul>
          <li>Class F (Full-time): 24 hours</li>
          <li>Class R (Reduced-time): 32 hours</li>
          <li>Class H (Part-time): 27 hours</li>
        </ul>
        <P>
          Sick time is earned at the end of each pay period. A chart of earning rates by employee class and pay
          frequency is set out below in Appendix C.
        </P>
        <H3>Sick and safe time carryover</H3>
        <P>
          You may carry over earned, unused Sick and Safe Time from one calendar year to the next up to the carry over
          limit. If you have more sick time than that at the end of the year, the amount that exceeds the limit will be
          applied to your Paid Personal time carry over limit. Any sick time in excess of those amounts will be
          forfeited.
        </P>
        <H3>Carry Over limits:</H3>
        <ul>
          <li>Sick and safe time</li>
          <ul>
            <li>Class F (Full-time): 24 hours</li>
            <li>Class R (Reduced-time): 36 hours</li>
            <li>Class H (Part-time): 48 hours</li>
          </ul>
          <li>Paid personal time</li>
          <ul>
            <li>Class F (Full-time): 48 hours</li>
            <li>Class R (Reduced-time): 36 hours</li>
            <li>Class H (Part-time): 24 hours</li>
          </ul>
        </ul>
        <H3>Example</H3>
        <P>
          Aven is a Class F employee and ends the year with 35 hours of sick time and 32 hours of PPT. 24 hours of sick
          time will be carried over to next year and will be available as sick time, 32 hours of PPT will be carried
          over to next year and will be available as PPT, and an additional 11 hours of sick time will be carried over
          to next year and will be converted to PPT. Aven will begin the next year with 43 hours of PPT and 24 hours of
          sick time.
        </P>
        <H3>Sick and safe time balance cap</H3>
        <P>There is no Sick and Safe Time balance cap.</P>
        <H3>Allowable usage</H3>
        <P>
          Sick and Safe Time can only be used for sick and safe time purposes as defined by law. This includes, but is
          not limited to, the following reasons:
          <ol>
            <li>
              Illness, injury or health condition or for preventive care for an employee or an employee’s partner,
              family members, or certain other members of the employee’s household;
            </li>
            <li>
              Reasons related to domestic violence, sexual assault, or stalking of an employee or an employee’s partner,
              family members or certain other members of the employee’s household; and,
            </li>
            <li>School or workplace closure by a public official to limit health hazards.</li>
          </ol>
          For a more comprehensive list of purposes, review the Paid Sick Time Purposes document.
        </P>
        <H3>Using sick and safe time</H3>
        <H4>Schedule use In advance when possible</H4>
        <P>
          Whenever possible, schedule Sick and Safe Time in advance. The way you schedule sick time may differ depending
          on what timekeeping system your team uses. If you are unsure how to schedule sick time, ask your manager, your
          HR team, or visit My HR. If you are not able to schedule sick time in advance you still must follow Amazon’s
          absence reporting procedures, unless you cannot reasonably do so. If you cannot follow the absence reporting
          procedures due to circumstances out of your control, you should notify your manager or HR as soon as you are
          able. If you are unable to provide notice personally, notice may be provided by someone else (for example,
          spouse, domestic partner, adult family member, or other responsible party). If you take more than 3
          consecutive days off without advance notice, your HR team may require an explanation or reasonable
          documentation from you explaining why you could not provide advance notice of your absence.
        </P>
        <H4>Failure to record sick time may lead to discipline</H4>
        <P>
          You are required to report the sick time that you use. If you take time off, and fail to record it, you may be
          subject to discipline. Discipline can include actions up to and including termination, as permitted by law.
        </P>
        <H4>Sick and safe time increments and future- and post-dated use</H4>
        <P>
          Sick time can be used in increments that match what is available under the timekeeping system you use. For
          example, if your timekeeping system tracks time in 6-minute increments, the smallest possible amount of sick
          time you can use is 6 minutes. Sick time will be deducted from your balance when the date you are using it has
          passed.
        </P>
        <H4>Excess sick time usage</H4>
        <P>
          If you use more sick time than you have available, the excess will be deducted from any earned, unused Paid
          Personal Time.
        </P>
      </>
    ),
  },
};
