import { useQuery } from '@tanstack/react-query';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';
import { METRIC_UNIT } from 'src/models/Metrics';
import { GetRoutingConfigurationBuilder } from "src/builders/GetRoutingConfiguration";
import {IRoutingConfiguration} from "src/models/RoutingConfiguration";
import {LIVE_HELP_CONTEXT} from "src/constants/common";

const katalLogger = new KatalLoggerClient();

export const useGetRoutingConfiguration = (employeeId: string, liveHelpContext: LIVE_HELP_CONTEXT, shouldFetch: boolean) => {
  return useQuery<IRoutingConfiguration, any>({
    queryKey: ['useGetRoutingConfiguration', employeeId, liveHelpContext],
    queryFn: async () => {
      return await GetRoutingConfigurationBuilder.getRoutingConfigurationByContext(
        employeeId,
        liveHelpContext
      )
    },
    retry: false,
    enabled: shouldFetch,
    staleTime: 60*60*1000,
    onSuccess: () => {
      katalLogger.logEMFMetric({
        metrics: [
          {
            metricName: 'GetRoutingConfigAPISuccess',
            metricUnit: METRIC_UNIT.COUNT,
            metricValue: 1,
          },
        ],
        dimensions: []
      }, {
        employeeId: employeeId,
        liveHelpContext: liveHelpContext
      });
    },
    onError: () => {
      katalLogger.logEMFMetric({
        metrics: [
          {
            metricName: 'GetRoutingConfigAPIFailure',
            metricUnit: METRIC_UNIT.COUNT,
            metricValue: 1,
          },
        ],
        dimensions: []
      }, {
        employeeId: employeeId,
        liveHelpContext: liveHelpContext
      });
    }
  });
};
