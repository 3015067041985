import { ExceptionDetail } from 'src/models/ExceptionDetail';
import { useQuery } from '@tanstack/react-query';
import { IVoiceContactProps, StartVoiceContactBuilder } from 'src/builders/StartVoiceContactBuilder';
import { StartVoiceContactResponse } from 'src/models/ServiceResponse/StartVoiceContactResponse';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';
import { METRIC_UNIT } from '../models/Metrics';

const katalLogger = new KatalLoggerClient();
export const useStartVoiceContact = (voiceContactProps: IVoiceContactProps) => {
  return useQuery<StartVoiceContactResponse, ExceptionDetail>({
    queryKey: ['useStartVoiceContact', voiceContactProps],
    queryFn: async () => {
      return StartVoiceContactBuilder.startVoiceContact(voiceContactProps);
    },
    retry: false,
    enabled: false,
    onSuccess: () => {
      katalLogger.logEMFMetric({
        metrics: [
          {
            metricName: 'VoiceContactAPISuccess',
            metricUnit: METRIC_UNIT.COUNT,
            metricValue: 1,
          },
        ],
        dimensions: []
      }, {});
    },
    onError: () => {
      katalLogger.logEMFMetric({
        metrics: [
          {
            metricName: 'VoiceContactAPIFailure',
            metricUnit: METRIC_UNIT.COUNT,
            metricValue: 1,
          },
        ],
        dimensions: []
      }, {});
    }
  });
};
