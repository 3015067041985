import { i18n } from 'i18next';

/** Please update loadLocale if there is a new language */
import atozdashboardtaacomponents_afZA from '@amzn/atoz-dashboard-taa-components/dist/assets/translations/atozdashboardtaacomponents-af-ZA.puff.json';
import atozdashboardtaacomponents_arAE from '@amzn/atoz-dashboard-taa-components/dist/assets/translations/atozdashboardtaacomponents-ar-AE.puff.json';
import atozdashboardtaacomponents_arSA from '@amzn/atoz-dashboard-taa-components/dist/assets/translations/atozdashboardtaacomponents-ar-SA.puff.json';
import atozdashboardtaacomponents_bgBG from '@amzn/atoz-dashboard-taa-components/dist/assets/translations/atozdashboardtaacomponents-bg-BG.puff.json';
import atozdashboardtaacomponents_bnIN from '@amzn/atoz-dashboard-taa-components/dist/assets/translations/atozdashboardtaacomponents-bn-IN.puff.json';
import atozdashboardtaacomponents_csCZ from '@amzn/atoz-dashboard-taa-components/dist/assets/translations/atozdashboardtaacomponents-cs-CZ.puff.json';
import atozdashboardtaacomponents_deDE from '@amzn/atoz-dashboard-taa-components/dist/assets/translations/atozdashboardtaacomponents-de-DE.puff.json';
import atozdashboardtaacomponents_enUS from '@amzn/atoz-dashboard-taa-components/dist/assets/translations/atozdashboardtaacomponents-en-US.puff.json';
import paycodes_csCZ from '@amzn/atoz-dashboard-taa-components/dist/assets/locales/localizedPayCodes/cs-CZ.puff.json';
import paycodes_deDE from '@amzn/atoz-dashboard-taa-components/dist/assets/locales/localizedPayCodes/de-DE.puff.json';
import paycodes_enUS from '@amzn/atoz-dashboard-taa-components/dist/assets/locales/localizedPayCodes/en-US.puff.json';
import timehubassistant_enUS from './translations/timehubassistant-en-US.puff.json';


const DEFAULT_TRANSLATION_NAMESPACE = 'translation';
interface TranslationLanguageResource {
  languageKeys: ReadonlyArray<string>;
  resources: Record<string, string>;
  localizedPaycodeResources?: Record<string, string>;
  namespace?: string;
}

// TODO: Add other countries to mapping if and when needed
const TRANSLATION_LANG_RESOURCE_MAPPING: ReadonlyArray<TranslationLanguageResource> = [
  {
    languageKeys: ["en", "en-US"],
    resources: {...atozdashboardtaacomponents_enUS.resources, ...timehubassistant_enUS.resources},
    localizedPaycodeResources: {...paycodes_enUS.resources}
  },
  {
    languageKeys: ["af", "af-ZA"],
    resources: {...atozdashboardtaacomponents_afZA.resources},
    localizedPaycodeResources: {}
  },
  {
    languageKeys: ["ar", "ar-AE"],
    resources: {...atozdashboardtaacomponents_arAE.resources},
    localizedPaycodeResources: {}
  },
  {
    languageKeys: ["ar-SA"],
    resources: {...atozdashboardtaacomponents_arSA.resources},
    localizedPaycodeResources: {}
  },
  {
    languageKeys: ["bg", "bg-BG"],
    resources: {...atozdashboardtaacomponents_bgBG.resources},
    localizedPaycodeResources: {}
  },
  {
    languageKeys: ["bn", "bn-IN"],
    resources: {...atozdashboardtaacomponents_bnIN.resources},
    localizedPaycodeResources: {}
  },
  {
    languageKeys: ["cs", "cs-CZ"],
    resources: {...atozdashboardtaacomponents_csCZ.resources, ...paycodes_csCZ.resources},
    localizedPaycodeResources: {}
  },
  {
    languageKeys: ["de", "de-DE"],
    resources: {...atozdashboardtaacomponents_deDE.resources, ...paycodes_deDE.resources},
    localizedPaycodeResources: {}
  },

]

const loadLocalizedPaycodes = (lng: string, resources: { [key: string]: string }, i18next: i18n, namespace: string) => {
  try {
    Object.keys(resources).forEach((key: string) => {
      i18next.addResource(lng, namespace, key, resources[key], { keySeparator: '|' });
    });
  } catch (error) {
    console.log('Time Hub Assistant loadLocalizedPaycodes Error: ', error);
  }
};

const loadLocale = (i18next: i18n): void => {
  TRANSLATION_LANG_RESOURCE_MAPPING.forEach((translationResourceInfo) => {
    const translationNamespace = translationResourceInfo.namespace ?? DEFAULT_TRANSLATION_NAMESPACE;
    translationResourceInfo.languageKeys.forEach((lang) => {
      i18next.addResources(lang, translationNamespace, translationResourceInfo.resources);
      translationResourceInfo.localizedPaycodeResources && loadLocalizedPaycodes(lang, translationResourceInfo.localizedPaycodeResources, i18next, translationNamespace);
    })
  })
};

export default loadLocale;
