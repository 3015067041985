import {TaaConfigs} from "@amzn/atoz-dashboard-taa-components";

export const getTAAComponentConfig = (employeeInfo: EmployeeInfo): TaaConfigs => {
  return {
    clientName: 'AtoZ-TimeHub-Assistant',
    host: undefined,
    associateInfo: {
      employeeId: employeeInfo.employeeId,
      locale: employeeInfo.languageLocale,
      siteId: employeeInfo.buildingCode,
      timeZone: employeeInfo.timeZoneId
    }
  }
}
