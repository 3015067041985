// TODO to be moved to translations
export const DISPLAY_STRINGS = {
  SOMETHING_WENT_WRONG: 'Something went wrong.', // TODO: to replace with user friendly message.
  WELCOME_CHAT_MESSAGE: 'Hi, what can I help you with on your timecard.',
  SHOW_SUPPORTING_OPTIONS_TEXT: 'Sure, happy to connect you to an agent, please click here to initiate the call!',
  SHOW_FALLBACK_SUPPORT_OPTIONS_TEXT: 'Sorry, I dont have answer to this query.' +
    ' I am Timehub AI assistant to help employee with their time related queries. ' +
    'You can connect with service advisor for more information!',
  SHOW_INITIATED_SUCCESS_CALL_MESSAGE: 'Thanks for the request, An agent will call you shortly!',
  SHOW_ERROR_MESSAGE: 'An error occurred, please try again!'
};

export const TIMEHUB_ASSISTANT_CHAT_CONST = {
  TIMEHUB_ASSISTANT_TITLE: 'TimeHub Assistant',
  NEW_CHAT: 'Start new chat',
  NEW_CALL: 'Start call',
  LIVE_SUPPORT: 'Live support',
  NEW_SESSION: 'New session'
};
