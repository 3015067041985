import KatalLogger, {Level, LoggerConfig} from '@amzn/katal-logger';
import {TimeHubAssistantLoggerClient} from "src/logger/TimeHubAssistantLoggerClient";
import {DIMENSION, EMFMetricPayload} from "src/models/Metrics";
import {API_ENDPOINTS, STAGE_TO_TIMEHUB_ASSISTANT_ENDPOINT} from "src/constants/Urls";
import {getCurrentStage} from "src/constants/StageConfig";

const LOGGER_ERROR_MESSAGE = 'Error occurred while sending metric';
const NAMESPACE = 'TimeHubAssistant';
export class KatalLoggerClient implements TimeHubAssistantLoggerClient {
  private logger: KatalLogger | null = null;
  private initKatalLoggerInstance(katalLoggerConfig: LoggerConfig): KatalLogger {
    return new KatalLogger(katalLoggerConfig);
  }

  private async getKatalLogger(): Promise<KatalLogger> {
    const katalLoggerConfig: LoggerConfig = {
      url: STAGE_TO_TIMEHUB_ASSISTANT_ENDPOINT[getCurrentStage()] + API_ENDPOINTS.LOG_URL,
      logThreshold: Level.INFO,
      maxLogLineSize: 10000,
      logToConsole: false,
    };

    if (!this.logger) {
      this.logger = this.initKatalLoggerInstance(katalLoggerConfig);
    }
    return this.logger;
  }

  async info(
    message: string,
    context?: Record<string, unknown>,
  ): Promise<void> {
    try {
      await (await this.getKatalLogger()).info(message, context);
    } catch (error: any) {
      console.log(LOGGER_ERROR_MESSAGE);
    }
  }


  async logEMFMetric(metricPayload: EMFMetricPayload, context: Record<string, any>): Promise<void> {
    try {
      const emfLog = this.createEmfLog(metricPayload);
      await (await this.getKatalLogger()).info('EMF Metric', { emfLog: { ...emfLog, ...context } });
    } catch (error: any) {
      console.log(LOGGER_ERROR_MESSAGE);
    }
  }

  private createEmfLog = ({ metrics, dimensions, namespace = NAMESPACE }: EMFMetricPayload) => {
    const allDimensions = this.getDimensionStructure(dimensions);
    const metricsMap: Record<string, any> = {}
    metrics.forEach((metric) => {
        metricsMap[metric.metricName] = metric.metricValue
    })
    return {
      _aws: {
        Timestamp: Date.now(),
        CloudWatchMetrics: [
          {
            Namespace: namespace,
            Dimensions: allDimensions.dimensionNames,
            Metrics: metrics.map((metric) => {
              return {
                Name: metric.metricName,
                Unit: metric.metricUnit,
              };
            }),
          },
        ],
      },
      ...allDimensions.dimensionValues,
      ...metricsMap,
    };
  };

  private getDimensionStructure = (dimension: ReadonlyArray<DIMENSION>) => {
    const dimensionNames: string[][] = [];
    const dimensionValues: any = {};
    dimension.forEach((group) => {
      dimensionNames.push(Object.keys(group));
      Object.keys(group).forEach((record) => {
        dimensionValues[record] = group[record];
      });
    });
    return {
      dimensionNames,
      dimensionValues,
    };
  };


  async error(
    message: string,
    error: Error,
    context?: Record<string, unknown>,
  ): Promise<void> {
    try {
      await (await this.getKatalLogger()).error(message, {error, context});
    } catch (error: any) {
      console.log(LOGGER_ERROR_MESSAGE);
    }
  }
}
