import { ChatMessageBubbleType } from '@amzn/stencil-react-chat-ui';
import React from 'react';
import { WidgetType } from 'src/models/WidgetView';

export interface SuggestionOptionConfig {
  optionType: SuggestionType;
  optionText: string;
  optionInfo?: string;
  onClick?: (event: React.MouseEvent) => void;
  url?: string;
  optionSuggestion?: string;
}

export enum SuggestionOption {
  APPLY_TIMEOFF = 'APPLY_TIMEOFF',
  CORRECT_PUNCHES = 'CORRECT_PUNCHES',
  REQUEST_ABSENCE = 'REQUEST_ABSENCE',
  CORRECT_SCHEDULE = 'CORRECT_SCHEDULE',
  CHECK_UPT_BALANCE_MESSAGE = 'CHECK_UPT_BALANCE_MESSAGE',
  CHANGE_PUNCH_MESSAGE = 'CHANGE_PUNCH_MESSAGE',
  SURVEY_LINK = 'SURVEY_LINK',
  CHECK_TIME_OFF_BALANCE_MESSAGE = 'CHECK_TIME_OFF_BALANCE_MESSAGE',
  LATE_FOR_SHIFT_MESSAGE = 'LATE_FOR_SHIFT_MESSAGE',
  PLANNING_TIME_OFF_MESSAGE = 'PLANNING_TIME_OFF_MESSAGE',
  CHECK_UPT_HISTORY_MESSAGE = 'CHECK_UPT_HISTORY_MESSAGE',
}

export enum SuggestionType {
  MESSAGE_TEXT = 'MESSAGE_TEXT',
  ATOZ_WORKFLOW_LINK = 'ATOZ_WORKFLOW_LINK',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  SURVEY_MODAL = 'SURVEY_MODAL',
}

export interface ChatWidgetInfo {
  widgetId: WidgetType;
  widgetInputs: Record<string, string>;
}

export enum CitationType {
  WORKED_HOURS_SCHEDULE_PUNCHES = 'WORKED_HOURS_SCHEDULE_PUNCHES',
  UPT_POLICY = 'UPT_POLICY',
  TIME_OFF_GENERAL_POLICY = 'TIME_OFF_GENERAL_POLICY',
}

export interface Citation {
  citationId: CitationType;
}

export interface ChatMessage {
  type: ChatMessageBubbleType;
  message: string | undefined;
  messageId?: string;
  showFeedback: boolean;
  suggestionOptions?: ReadonlyArray<SuggestionOption>;
  dataViewType?: WidgetType;
  widgetInfo?: ChatWidgetInfo;
  citations?: ReadonlyArray<Citation>;
  hasError?: boolean;
  attributes?: Record<string, string>;
}

export type ChatMessageList = ReadonlyArray<ChatMessage>;
