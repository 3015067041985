import React from 'react';
import ReactDOM from 'react-dom';

import { App } from 'src/components/App';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { AppInitWrapper } from './components/AppInitWrapper';

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <AppInitWrapper>
      <React.Suspense fallback={<Spinner size={SpinnerSize.Large} />}>
        <App />
      </React.Suspense>
    </AppInitWrapper>
  </React.StrictMode>,
  document.getElementById('root'),
);
