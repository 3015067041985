import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { styledWithTheme } from "@amzn/stencil-react-components/context";
import { Col, Flex } from "@amzn/stencil-react-components/layout"
import { Modal } from "@amzn/stencil-react-components/modal"
import { H2, Text } from "@amzn/stencil-react-components/text"
import {FC, ImgHTMLAttributes } from "react";
// @ts-ignore
import EmptyClockSVGPath from "src/assets/empty-clock.svg";

export const EmptyClock: FC<ImgHTMLAttributes<HTMLImageElement>> = (props) => (
  <img src={EmptyClockSVGPath} {...props} />
);

const StyledClock = styledWithTheme(EmptyClock)(
  ({ theme }) => `
    margin: ${theme.selectors.space('S400')}px 0;
`
);

interface SessionProps {
  showSessionEndModal: boolean;
  setShowSessionEndModal: React.Dispatch<React.SetStateAction<boolean>>;
  onNewChatSessionClickHandler: () => void;
  setShowFeedbackModal: React.Dispatch<React.SetStateAction<boolean>>
}
export const SessionEndModal = (props: SessionProps) => {
  const close = () => {
    props.setShowSessionEndModal(false);
  };

  const startNewSessionHandler = () => {
      close();
      props.onNewChatSessionClickHandler();
  }

  const submitFeedbackHandler = () => {
    props.setShowFeedbackModal(true)
  }

  return <Modal isOpen={props.showSessionEndModal} close={close}>
    <Col
      backgroundColor="neutral0"
      gridGap="S300"
      padding="S200"
      alignItems="center">
      <H2>Session Ended</H2>
      <StyledClock alt="Empty Clock" />
      <Text>
        Your session has ended, please click the below button to restart.
      </Text>
      <Flex width='100%' gridGap="S100" margin="S50" flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-evenly'}>
        <Button onClick={startNewSessionHandler} variant={ButtonVariant.Primary}>
          Start new session
        </Button>
        <Button onClick={submitFeedbackHandler}>
          Submit Feedback
        </Button>
      </Flex>
    </Col>
  </Modal>
}
