import { ErrorViewConfig } from 'src/models/ErrorViewConfig';
import { DISPLAY_STRINGS } from 'src/constants/DisplayMessages';

export enum SERVICE_ERROR_CODE {
  DEFAULT_ERROR = 'DEFAULT_ERROR',
}

export const ErrorCodeToViewConfig: Record<string, ErrorViewConfig> = {
  //TODO: Move to translations
  [SERVICE_ERROR_CODE.DEFAULT_ERROR]: {
    message: DISPLAY_STRINGS.SOMETHING_WENT_WRONG,
    isRetryable: false,
  },
};
