export const CUSTOM_Z_INDEX_VALUE_700 = 700;

export enum TimeHubAssistantPage {
  CHAT_PAGE = 'chat-page',
  HOME_PAGE = 'home-page',
}
export enum HTTP_METHODS {
  GET = 'get',
  POST = 'post',
}
export const OPERATORS_CONSTANTS = {
  EQUALS: '=',
  QUESTION_MARK: '?',
  AND: '&',
};

export enum ContentType {
  PLAIN_TEXT = 'plain/text',
  APPLICATION_JSON = 'application/json',
}

export enum LIVE_HELP_CONTEXT {
  SOMETHING_ELSE = 'MyHR$Something Else',
}

export const ALLOWED_EMPLOYEE_IDS_FOR_SPOOFING = [
  '102299480',
  '697960',
  '112087479',
  '112756851',
  '106078178',
  '102305959',
  '375347',
  '109329679',
  '112798223',
  '111813236',
  '104903139',
  '201033243',
  '104347825',
];
