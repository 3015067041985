export interface ChatConnectionDetails {
  contactId: string;
  participantId: string;
  participantToken: string;
}

export enum ConnectEventType {
  MESSAGE = 'MESSAGE',
  EVENT = 'EVENT',
}

export enum ConnectEventParticipantRole {
  CUSTOM_BOT = 'CUSTOM_BOT',
  CUSTOMER = 'CUSTOMER',
}

export enum ConnectionStatus {
  CONNECTING,
  BOT_CONNECTING,
  CONNECTED,
  DISCONNECTED,
  SENDING_MESSAGE,
  SENT_MESSAGE,
  AWAITING_RESPONSE,
  FAILED,
}
