import { StartChatContactResponse } from 'src/models/ServiceResponse/StartChatContactResponse';
import { ExceptionDetail } from 'src/models/ExceptionDetail';
import { useQuery } from '@tanstack/react-query';
import { IStartChatContactProps, StartChatContactBuilder } from 'src/builders/StartChatContactBuilder';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';
import { METRIC_UNIT } from 'src/models/Metrics';

const katalLogger = new KatalLoggerClient();
export const useStartChatContact = (startChatContactProps: IStartChatContactProps) => {
  return useQuery<StartChatContactResponse, ExceptionDetail>({
    queryKey: ['useStartChatContactBuilder', startChatContactProps.employeeId],
    queryFn: async () => {
      return StartChatContactBuilder.startChatContact(
        startChatContactProps.employeeId,
        startChatContactProps.employeeInfo,
      );
    },
    retry: false,
    enabled: false,
    onSuccess: (startChatContactResponse) => {
      katalLogger.logEMFMetric(
        {
          metrics: [
            {
              metricName: 'StartChatContactAPISuccess',
              metricUnit: METRIC_UNIT.COUNT,
              metricValue: 1,
            },
          ],
          dimensions: [],
        },
        {
          contactId: startChatContactResponse.contactId,
          employeeId: startChatContactProps.employeeId,
        },
      );
    },
    onError: (error) => {
      katalLogger.logEMFMetric(
        {
          metrics: [
            {
              metricName: 'StartChatContactAPIFailure',
              metricUnit: METRIC_UNIT.COUNT,
              metricValue: 1,
            },
          ],
          dimensions: [],
        },
        {
          employeeId: startChatContactProps.employeeId,
        },
      );
    },
  });
};
