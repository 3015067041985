import { getCurrentStage, STAGE } from 'src/constants/StageConfig';

export const CHAT_CONTACT_CHANNEL_REGION = 'us-east-1';
const CHAT_CONTACT_CHANNEL_PROD_CONFIG = {
  instanceId: '2b67a692-d7ea-44a6-bd1e-9501245ce170',
  contactFlowId: '6e787670-3cab-4060-bd60-4530947d4f88',
};
const CHAT_CONTACT_CHANNEL_NON_PROD_CONFIG = {
  instanceId: '2b67a692-d7ea-44a6-bd1e-9501245ce170',
  contactFlowId: '6e787670-3cab-4060-bd60-4530947d4f88',
};
export const CHAT_CONTACT_CHANNEL_STAGE_CONFIG: Record<STAGE, { instanceId: string; contactFlowId: string }> = {
  [STAGE.TEST]: CHAT_CONTACT_CHANNEL_NON_PROD_CONFIG,
  [STAGE.ALPHA]: CHAT_CONTACT_CHANNEL_NON_PROD_CONFIG,
  [STAGE.BETA]: CHAT_CONTACT_CHANNEL_NON_PROD_CONFIG,
  [STAGE.GAMMA]: CHAT_CONTACT_CHANNEL_NON_PROD_CONFIG,
  [STAGE.PROD]: CHAT_CONTACT_CHANNEL_PROD_CONFIG,
};

const TAA_BUSINESS_LINE = 'ERC'; // Using TAA specific business line instead of routing business line as it is for TAA related queries

export const TIME_HUB_ASSISTANT_CHAT_PARAMS = {
  isTimeHubBuddyEnabled: 'true',
  timeHubAssistantStage: getCurrentStage(),
  businessLine: TAA_BUSINESS_LINE,
  userContext: 'AtoZ$Time, Leave of Absence and Accommodation$UPT/Attendance Points',
};
