// import AxiosHttpClient from "src/utils/HttpUtils/AxiosHttpClient";
// import {HTTP_METHODS} from "src/constants/common";
import axios from 'axios';
import * as uuid from 'uuid';
import { IDENTIFIER_TO_PROMPT_MAP } from './GetPromptMappingConfiguration';

export interface TimeHubSummaryData {
  text: string;
  suggestionIds: Array<string>;
  widgetId: string;
}

const getTimeHubSummary = async (identifier: string, employeeDetails?: EmployeeInfo) => {
  console.log('Logging before requesting is new ', employeeDetails);
  console.log('the identifier CHECK ==>', identifier);
  console.log('the prompt is ==> ', IDENTIFIER_TO_PROMPT_MAP.get(identifier));
  // const data = {
  //   "conversationId": "ef9fd006-4395-4068-8637-052cd230656f",//any UUID
  //   "messageData": {
  //     "text": "I want to view the summary of my Time and Attendence data in bullet pointers and provide me any suggestions if you observe any issues"
  //   },
  //   "employeeId": "107210835"
  // };
  const data = {
    conversationId: uuid.v4(),
    employeeInfo: {
      employeeId: employeeDetails?.employeeId,
      preferredName: employeeDetails?.preferredFirstName,
      countryCode: employeeDetails?.countryCode ?? 'USA',
      timeZoneId: employeeDetails?.timeZoneId,
      languageLocale: employeeDetails?.languageLocale,
      job_details: {
        jobClassType: employeeDetails?.jobDetails?.jobClassType ?? 'F',
        locationRegion: employeeDetails?.jobDetails?.locationRegion ?? 'USA-TX',
      },
    },
    employeeId: employeeDetails?.employeeId,
    messageData: {
      text: IDENTIFIER_TO_PROMPT_MAP.get(identifier)!,
    },
  };
  return await axios.post('https://9fiwsk0t1c.execute-api.us-east-1.amazonaws.com/gamma/employeeSummary', data); //gamma

  // return await axios.post('https://3tfgjo8emj.execute-api.us-east-1.amazonaws.com/beta/employeeSummary', data); prod
};

export const GetTimeHubSummaryBuilder = {
  getTimeHubSummary,
};
