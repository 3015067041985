import AxiosHttpClient from 'src/utils/HttpUtils/AxiosHttpClient';
import { API_ENDPOINTS, ATOZ_API_OPERATIONS, ATOZ_CLIENT_ID, QUERY_PARAMS } from 'src/constants/Urls';
import { HTTP_METHODS } from 'src/constants/common';
import { MyHRGatewayServiceResponse } from 'src/models/ServiceResponse/MyHRGatewayServiceResponse';
import { IGetRoutingConfigurationResponse } from 'src/models/ServiceResponse/GetRoutingConfigurationResponse';
import { IRoutingConfiguration, IRoutingConfigurationAttributes } from 'src/models/RoutingConfiguration';

const getRoutingConfigurationForUPTIntent = (employeeId: string) => {
  return getRoutingConfiguration(employeeId, {
    context: 'MyHR$Time, Leave of Absence and Accommodation$UPT/Attendance Points',
    clientId: 'AtoZ',
    userPreferredLocale: 'en-US',
    destinationTypes: ['CHAT', 'CALL'],
    multipleDestinationConfiguration: [{ destinationType: 'SELF_SERVICE', requireAllDestinations: false }],
  } as IRoutingConfigurationBody);
};

const getRoutingConfigurationByContext = async (employeeId: string, context: string) => {
  return await getRoutingConfiguration(employeeId, {
    context: context,
    clientId: 'AtoZ',
    userPreferredLocale: 'en-US',
    destinationTypes: ['CHAT', 'CALL'],
    multipleDestinationConfiguration: [{ destinationType: 'SELF_SERVICE', requireAllDestinations: false }],
  } as IRoutingConfigurationBody);
};

export interface IRoutingConfigurationBody {
  context: string;
  clientId: string;
  userPreferredLocale: string;
  destinationTypes: string[];
  multipleDestinationConfiguration: { destinationType: string; requireAllDestinations: boolean }[];
}
const getRoutingConfiguration = async (employeeId: string, requestBody: IRoutingConfigurationBody) => {
  const axiosConfig = AxiosHttpClient.buildAtoZAxiosRequestConfig(
    {
      url: API_ENDPOINTS.MY_HR_GATEWAY_SERVICE_ROUTER,
      method: HTTP_METHODS.POST,
      params: {
        [QUERY_PARAMS.EMPLOYEE_ID]: employeeId,
        [QUERY_PARAMS.MY_HR_CLIENT_ID]: ATOZ_CLIENT_ID.MY_HR_ROUTING_ENGINE,
        [QUERY_PARAMS.OPERATION]: ATOZ_API_OPERATIONS.GET_ROUTING_CONFIGURATIONS,
        [QUERY_PARAMS.PATH]: API_ENDPOINTS.ROUTING_CONFIGURATIONS,
      },
    },
    requestBody,
    ATOZ_CLIENT_ID.ATOZ_MYHRGATEWAY_SERVICE,
  );
  const response = await AxiosHttpClient.request(axiosConfig);
  return buildGetRoutingConfigurationResponse(response.data);
  //TODO: Add catch for exception logging as needed
};

const buildGetRoutingConfigurationResponse = (response: MyHRGatewayServiceResponse): IRoutingConfiguration => {
  const getRoutingConfigurationResponse: IGetRoutingConfigurationResponse = JSON.parse(
    response.response,
  ) as IGetRoutingConfigurationResponse;
  const chatConfigurationsArray: string[] = getRoutingConfigurationResponse?.response?.CHAT;
  const callConfigurationsArray: string[] = getRoutingConfigurationResponse?.response?.CALL;
  const chatDataAttributes: IRoutingConfigurationAttributes =
    chatConfigurationsArray && chatConfigurationsArray.length > 0 ? JSON.parse(chatConfigurationsArray[0]) : undefined;
  const callDataAttributes: IRoutingConfigurationAttributes =
    callConfigurationsArray && callConfigurationsArray.length > 0 ? JSON.parse(callConfigurationsArray[0]) : undefined;
  return {
    CHAT: chatDataAttributes,
    CALL: callDataAttributes,
  } as IRoutingConfiguration;
};

export const GetRoutingConfigurationBuilder = {
  getRoutingConfiguration: getRoutingConfiguration,
  getRoutingConfigurationForUPTIntent: getRoutingConfigurationForUPTIntent,
  getRoutingConfigurationByContext: getRoutingConfigurationByContext,
};
