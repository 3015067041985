import React, { createContext, ReactNode } from 'react';
import { ChatMessage, ChatMessageList } from 'src/models/ChatMessage';
import ConnectChatSessionManager from 'src/builders/ConnectChatSessionManager';
import { MessageRequest } from 'src/models/ChatSessionManager';
import { IRoutingConfiguration } from 'src/models/RoutingConfiguration';
import { ConnectionStatus } from 'src/models/ConnectChatSession';

export const DEFAULT_CHAT_MESSAGE_LIST: ChatMessageList = [];

export interface ChatSession {
  isChatSessionConnected: boolean;
  isChatSessionFailed: boolean;
  chatMessageList: ChatMessageList;
  setChatMessageList: (chatMessageList: ChatMessageList) => void;
  chatSessionManager?: ConnectChatSessionManager;
  sendMessage?: (messageRequest: MessageRequest) => Promise<ChatMessage | undefined>;
  employeeInfo?: EmployeeInfo;
  connectionStatus: ConnectionStatus;
  isSubmitMessageDisabled: boolean;
}

export const ChatSessionContext = createContext<ChatSession>({
  chatMessageList: DEFAULT_CHAT_MESSAGE_LIST,
  isChatSessionConnected: false,
  isChatSessionFailed: false,
  setChatMessageList: () => {},
  connectionStatus: ConnectionStatus.DISCONNECTED,
  isSubmitMessageDisabled: false,
});

export interface ChatSessionContextProviderProps {
  children: ReactNode;
  chatMessageList: ChatMessageList;
  isChatSessionConnected: boolean;
  isChatSessionFailed: boolean;
  setChatMessageList: (chatMessageList: ChatMessageList) => void;
  chatSessionManager?: ConnectChatSessionManager;
  employeeInfo?: EmployeeInfo;
  connectionStatus: ConnectionStatus;
  sendMessage?: (messageRequest: MessageRequest) => Promise<ChatMessage>;
}

export const ChatSessionContextProvider = ({
  children,
  chatMessageList,
  setChatMessageList,
  chatSessionManager,
  employeeInfo,
  isChatSessionConnected,
  isChatSessionFailed,
  connectionStatus,
}: ChatSessionContextProviderProps) => {
  const isSubmitMessageDisabled = connectionStatus !== ConnectionStatus.CONNECTED;
  const sendMessage = async (messageRequest: MessageRequest) => {
    if (isSubmitMessageDisabled) {
      return;
    }
    const message = await chatSessionManager?.sendMessage(messageRequest);
    const shouldNotDisplayOnUI = messageRequest.messageData.shouldNotDisplayOnUI == true;
    message && !shouldNotDisplayOnUI && setChatMessageList([...chatMessageList, message]);
    return message as ChatMessage;
  };

  return (
    <ChatSessionContext.Provider
      value={{
        chatMessageList: chatMessageList,
        setChatMessageList: setChatMessageList,
        chatSessionManager: chatSessionManager,
        sendMessage: sendMessage,
        employeeInfo: employeeInfo,
        isChatSessionConnected: isChatSessionConnected,
        isChatSessionFailed: isChatSessionFailed,
        isSubmitMessageDisabled,
        connectionStatus: connectionStatus,
      }}
    >
      {children}
    </ChatSessionContext.Provider>
  );
};
