import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import loadLocale from './loadLocale';

i18n.use(initReactI18next).init({
  resources: {},
  lng: 'en-US',
  fallbackLng: 'en-US',

  interpolation: {
    escapeValue: false,
  },
});

loadLocale(i18n);

export const setupI18nextLanguage = (lng: string) => {
  i18n.changeLanguage(lng);
  return i18n;
};

export default i18n;
