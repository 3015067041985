import { DateTime } from 'luxon';

export const getCurrentDateTimeInISOFormat = (): string => {
  return DateTime.utc().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
};

export const getDayStartDateTimeInISOFormat = (): string => {
  return DateTime.utc().startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
};

export const getInitialSelectedDateFromWidgetInput = (selectedDate?: string) => {
  if (selectedDate) {
    return DateTime.fromFormat(selectedDate, 'yyyy-MM-dd');
  }
  return DateTime.now();
};
