import React from 'react';
import { Text } from '@amzn/atoz-web/Text';
import { Row } from '@amzn/atoz-web/Row';
import { IconCheckCircleFill, IconSize } from '@amzn/atoz-web/Icons';
import { WidgetView, WidgetViewProps } from 'src/models/WidgetView';

interface WorkflowActionCompletedView extends WidgetViewProps {}
const WorkflowActionCompletedView: WidgetView = () => {
  return (
    <Row>
      <Text fontWeight={'bold'}>Your action has been completed</Text>
      <IconCheckCircleFill color={'green80'} size={IconSize.Medium} />
    </Row>
  );
};

export default WorkflowActionCompletedView;
