import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { WidgetView, WidgetViewProps } from 'src/models/WidgetView';
import { TimeOffBalanceTiles } from '@amzn/atoz-dashboard-taa-components/dist/components/TimeOffBalanceTiles';
import { getTAAComponentConfig } from 'src/components/Widgets/WidgetUtils';
import { TaaConfigsContextProvider } from '@amzn/atoz-dashboard-taa-components';

export interface TimeOffBalancesViewProps extends WidgetViewProps {}

const TimeOffBalancesView: WidgetView = ({ employeeInfo }: TimeOffBalancesViewProps) => {
  return (
    <>
      <QueryClientProvider client={new QueryClient()}>
        <TaaConfigsContextProvider taaConfigs={getTAAComponentConfig(employeeInfo)}>
          <TimeOffBalanceTiles employeeId={employeeInfo.employeeId} />
        </TaaConfigsContextProvider>
      </QueryClientProvider>
    </>
  );
};

export default TimeOffBalancesView;
