import React, { useContext, useEffect, useState } from 'react';
import { Col } from '@amzn/stencil-react-components/layout';
import { Label, Text } from '@amzn/stencil-react-components/text';
import { InputFooter, MaskedInput } from '@amzn/stencil-react-components/form';
import SupportOption from 'src/components/Widgets/supportOptions/SupportOption';
import { IconPhone } from '@amzn/stencil-react-components/icons';
import { ChatSessionContext } from 'src/contextProviders/ChatSessionContext';
import { useStartVoiceContact } from 'src/hooks/useStartVoiceContact';
import { IVoiceContactProps } from 'src/builders/StartVoiceContactBuilder';
import { removeBracketsAndHyphens } from 'src/utils/regexUtils';
import { ContentType, LIVE_HELP_CONTEXT } from 'src/constants/common';
import { useGetRoutingConfiguration } from 'src/hooks/useGetRoutingConfiguration';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';

interface IRequestCallBackProps {
  employeeId: string;
}
const RequestCallBack = (props: IRequestCallBackProps) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const { employeeInfo, sendMessage } = useContext(ChatSessionContext);
  const { data: routingConfigData, error: routingConfigError } = useGetRoutingConfiguration(
    props.employeeId,
    LIVE_HELP_CONTEXT.SOMETHING_ELSE,
    true,
  );

  const { refetch, isSuccess, error } = useStartVoiceContact({
    employeeId: props.employeeId,
    employeeInfo: employeeInfo,
    routingConfiguration: routingConfigData,
    phoneNumber: removeBracketsAndHyphens(phoneNumber),
  } as IVoiceContactProps);

  const sendMessageCallInitiated = async () => {
    sendMessage &&
      (await sendMessage({
        messageData: {
          text: `Request call to ${removeBracketsAndHyphens(phoneNumber)}`,
          callSupportOptionClicked: true,
          shouldNotDisplayOnUI: true,
        },
        contentType: ContentType.APPLICATION_JSON,
      }));
  };
  useEffect(() => {
    if (isSuccess) {
      sendMessageCallInitiated();
    }
  }, [isSuccess]);

  //TODO: Perform error handling
  //TODO: Move text to translations

  if (routingConfigError) return <Text>An error occurred, please try again later</Text>;
  if (routingConfigData)
    return (
      <Col gridGap="S200" width={'100%'}>
        <Text>Great! To set you up on a call, I need to confirm some details about you.</Text>
        <Label htmlFor="masked-input-id-1">Preferred phone number with country code</Label>
        <MaskedInput
          placeholder="(+) 555-555-2345"
          id="masked-input-id-1"
          mask="(+#) ###-###-#####"
          value={phoneNumber}
          error={error?.errorStatus == 400}
          onChange={(event) => setPhoneNumber(event.target.value)}
        />
        {error?.errorStatus == 400 && (
          <InputFooter id="error-phone-number-input" error>
            Provided number is invalid, please enter valid phone number
          </InputFooter>
        )}
        <Text>
          You’ll instantly receive a call from {routingConfigData?.CALL?.attributes.sourcePhoneNumber}. Stay on the line
          and an agent will connect with you shortly.
        </Text>
        <SupportOption
          leftIconComponent={<IconPhone />}
          textData={'Request a call'}
          onClick={async () => {
            refetch().then();
          }}
        />
        {error && error?.errorStatus != 400 && (
          <MessageBanner type={MessageBannerType.Error}>An error occurred while initiating callback</MessageBanner>
        )}
      </Col>
    );
  return <Spinner size={SpinnerSize.Medium}></Spinner>;
};

export default RequestCallBack;
