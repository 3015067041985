import React, { useContext } from 'react';
import { IconPhone } from '@amzn/stencil-react-components/icons';
import { ContentType } from 'src/constants/common';
import SupportOption from 'src/components/Widgets/supportOptions/SupportOption';
import { ChatSessionContext } from 'src/contextProviders/ChatSessionContext';

const LiveSupportOption = () => {
  const { sendMessage } = useContext(ChatSessionContext);
  //TODO: Based on availability of call option render it
  return (
    <SupportOption
      leftIconComponent={<IconPhone />}
      textData={'Call'}
      onClick={async () => {
        sendMessage &&
          (await sendMessage({
            messageData: {
              text: 'Call',
              shouldShowCallOption: true,
            },
            contentType: ContentType.APPLICATION_JSON,
          }));
      }}
    />
  );
};
export default LiveSupportOption;
