import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';
import { ATOZ_API_GATEWAY_ENDPOINT, ATOZ_CLIENT_ID } from 'src/constants/Urls';
import { getCurrentStage } from 'src/constants/StageConfig';

/**
 * Take service config merge with axios default config.
 * Create an instance of axios request.
 */
const executeRequest = async (config: AxiosRequestConfig) => {
  return axios.request(config);
};

const buildAtoZAxiosRequestConfig = (requestConfig: AxiosRequestConfig, data: any, clientId: string) => {
  let config = { ...requestConfig };
  config.data = data ? data : null;
  config.headers = {
    'content-type': 'application/json',
    Authorization: `Bearer ${new Cookies().get('atoz-oauth-token')}`,
    //TODO update the client id once onboarded
    'x-atoz-client-id': clientId,
  };
  config = { ...config, ...envRequestConfig(config) };
  return config;
};

const envRequestConfig = (config: AxiosRequestConfig) => {
  return {
    url: ATOZ_API_GATEWAY_ENDPOINT[getCurrentStage()] + config.url,
    withCredentials: true,
  };
};

/**
 * Http Provider is abstraction layer between axios and other services, components.
 */
const AxiosHttpClient = {
  request: executeRequest,
  buildAtoZAxiosRequestConfig: buildAtoZAxiosRequestConfig,
};

export default AxiosHttpClient;
