export type DIMENSION = Record<string, string>;

export type EMFMetricPayload = {
  metrics: Array<Metrics>
  dimensions: Array<DIMENSION>;
  namespace?: string;
};

export interface Metrics {
  metricName: string;
  metricUnit: METRIC_UNIT
  metricValue: any;
}
export enum METRIC_UNIT {
  COUNT = 'Count',
  NONE = "None",
  MILLISECONDS = 'Milliseconds'
}
