import AxiosHttpClient from 'src/utils/HttpUtils/AxiosHttpClient';
import { API_ENDPOINTS, ATOZ_API_OPERATIONS, ATOZ_CLIENT_ID, QUERY_PARAMS } from 'src/constants/Urls';
import { HTTP_METHODS } from 'src/constants/common';
import { MyHRGatewayServiceResponse } from 'src/models/ServiceResponse/MyHRGatewayServiceResponse';
import {
  Attributes,
  StartVoiceContactRequest,
  VoiceContactChannel,
} from 'src/models/ServiceRequest/ContactRequestInterface';
import { IRoutingConfiguration } from 'src/models/RoutingConfiguration';
import { buildExceptionResponse } from 'src/utils/Exception/ExceptionUtil';
import { StartVoiceContactResponse } from 'src/models/ServiceResponse/StartVoiceContactResponse';
import { emitSessionLoadDurationMetrics } from 'src/utils/metricUtil';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';

export interface IVoiceContactProps {
  employeeId: string;
  employeeInfo: EmployeeInfo;
  routingConfiguration: IRoutingConfiguration;
  phoneNumber: string;
}

const initiateStartVoiceContact = async (voiceContact: IVoiceContactProps) => {
  const apiInvocationStart: number = Date.now();
  try {
    const axiosConfig = AxiosHttpClient.buildAtoZAxiosRequestConfig(
      {
        url: API_ENDPOINTS.MY_HR_GATEWAY_SERVICE_ROUTER,
        method: HTTP_METHODS.POST,
        params: {
          [QUERY_PARAMS.EMPLOYEE_ID]: voiceContact.employeeId,
          [QUERY_PARAMS.MY_HR_CLIENT_ID]: ATOZ_CLIENT_ID.MY_HR_LIVE_HELP,
          [QUERY_PARAMS.OPERATION]: ATOZ_API_OPERATIONS.START_VOICE_CONTACT,
        },
      },
      {
        attributes: {
          preferredName: voiceContact.employeeInfo.preferredFullName || voiceContact.employeeId,
          location_country: voiceContact.employeeInfo.countryCode,
          queueId: voiceContact.routingConfiguration.CALL?.attributes?.queueInfo,
          queuePassed: 'true',
          userLanguage: voiceContact.employeeInfo.languageLocale,
          userContext: voiceContact.routingConfiguration.CALL?.context,
          businessLine: voiceContact.routingConfiguration.CALL?.attributes?.businessLine,
          waitTimeInMin: '0',
          isWaitTimeDisplayed: 'false',
          timeStampForLiveHelpClick: Date.now().toString(),
          timeStampForChatClick: Date.now().toString(),
          isAtoZApp: 'true',
          chat_recording_optin: 'Consent',
          utm_source: 'TimeCard',
          evaUserAccess: 'false',
          userIssueFreeForm: 'test',
          deviceType: 'mobile',
        } as Attributes,
        voiceContactChannel: {
          instanceId: voiceContact.routingConfiguration.CALL?.attributes?.instanceId,
          contactFlowId: voiceContact.routingConfiguration.CALL?.attributes?.contactFlowId,
          queueId: voiceContact.routingConfiguration.CALL?.attributes?.queueInfo,
          region: voiceContact.routingConfiguration.CALL?.attributes?.region,
          sourcePhoneNumber: voiceContact.routingConfiguration.CALL?.attributes?.sourcePhoneNumber,
        } as VoiceContactChannel,
        destinationPhoneNumber: voiceContact.phoneNumber,
        connectAWSAccount: voiceContact.routingConfiguration.CALL?.attributes.awsAccount,
      } as StartVoiceContactRequest,
      ATOZ_CLIENT_ID.ATOZ_MYHRGATEWAY_SERVICE,
    );
    const response = await AxiosHttpClient.request(axiosConfig);
    emitSessionLoadDurationMetrics(new KatalLoggerClient(), apiInvocationStart, 'StartVoiceContactLatency', {
      employeeId: voiceContact.employeeId,
    });
    return buildStartVoiceContactResponse(response.data);
  } catch (error) {
    //TODO: Add metric for error
    throw buildExceptionResponse(error);
  }
};

const buildStartVoiceContactResponse = (response: MyHRGatewayServiceResponse): StartVoiceContactResponse => {
  return JSON.parse(response.response) as StartVoiceContactResponse;
};

export const StartVoiceContactBuilder = {
  startVoiceContact: initiateStartVoiceContact,
};
